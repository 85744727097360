import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {
   Dialog,
   DialogActions,
   DialogContent,
} from "@mui/material";
import TextTypeField from "../input/textfield/TextTypeField";
import { closeIfEsc, dialogClosed, preventEventPropagation } from "../../utils/fleader-common";
import OkBtn from "../button/OkBtn";
import i18n from "../../utils/i18n-utils";
import CancelBtn from "../button/CancelBtn";
import { useToastDispatch } from "../../context/ToastContext";

export type TeleServiceInfo = {
   createDtime?: string,
   updateDtime?: string,
   facilityId?: string,
   serviceId?: string,
   serviceName?: string,
   receiveUrl?: string,
   sendUrl?: string,
   receiveAccessKey?: string,
   sendAccessKey?: string,
   isActive?: boolean,
   isSendReference?: boolean,
   isOtherStorage?: boolean
   emergency?: boolean
}

export default function TeleRequestDialog(props: any) {
   // State
   const [open, setOpen] = useState(true);
   const { content, caseId, isEmergency } = props.message;
   const [service, setService] = useState<TeleServiceInfo>(content[0]);

   // Context
   const toastDispatch = useToastDispatch();

   const handleClose = (event?: any, reason?: string) => {
      if (reason === "backdropClick") {
         return;
      }
      setOpen(false);
      dialogClosed();
   };

   function doOk() {
      const { serviceId } = service;
      if (serviceId) postFetchRequestTele(caseId, serviceId, isEmergency);
   }

   function postFetchRequestTele(id: string, serviceId: string, isEmergency = false) {
      return new Promise((resolve, reject) => {
         const { facilityId } = JSON.parse(localStorage.getItem("user")!);
         const param: TeleServiceInfo = {};
         param.serviceId = serviceId;
         param.emergency = isEmergency;

         fetch(`/api/tele?caseId=${id}&facilityId=${facilityId}`, {
            method: "POST",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(param),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((result) => {
                  resolve(result);
                  handleClose();
               });
            } else {
               // reject(new Error(`${response.status} ${response.statusText}`));
               reject(new Error(`Teleradiology on the study has been request failed.`));
            }
         }).catch((e) => {
            handleClose();
            toastDispatch({ type: "SET_TOAST", open: true, msg: e, isErr: true });
         });
      });
   }

   return (
      content
      && <Dialog
         open={open}
         onClose={handleClose}
         onClick={preventEventPropagation}
         onKeyDown={closeIfEsc}
         fullWidth
         maxWidth={"xs"}
         PaperProps={{ sx:{ height: "20%" } }} >
         <DialogContent
            align={"center"}
         >
            <Autocomplete
               disablePortal
               disableClearable
               fullWidth={true}
               options={content}
               getOptionLabel={options => options.serviceName}
               defaultValue={content[0]}
               isOptionEqualToValue={(option, value) => option.serviceId === value.serviceId}
               onChange={(event, value) => { setService(value); }}
               componentsProps={{
                  paper: {
                     sx: {
                        backgroundColor: "#1d1e25",
                     },
                  },
               }}
               renderInput={params => (
                  <TextTypeField
                     {...params}
                     shrink
                     label="Teleradiology service"
                     variant={"standard"}
                     size={"large"}
                     margin={"none"}
                     inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                     }}
                  />
               )}
            />
         </DialogContent>
         <DialogActions
            align={"center"}
            backgroundcolor={"darkBlue"}
         >
            <OkBtn
               onClick={doOk}
               text={i18n("button.send")}/>
            <CancelBtn
               onClick={handleClose}
               text={i18n("button.cancel")}/>
         </DialogActions>
      </Dialog>
   );
}
