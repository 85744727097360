import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox, Collapse, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import {
   ColDef,
   GetContextMenuItemsParams,
   GridOptions,
   MenuItemDef,
   RowClickedEvent,
   RowDoubleClickedEvent, RowDragEndEvent,
   RowNode,
   RowSelectedEvent,
} from "ag-grid-community";
import { useSelector } from "react-redux";
import {
   StyledButtonGroup,
   StyledContextButtonWrapper,
   StyledContextText,
   StyledDdpSetting,
   StyledDdpSettingGroup,
   StyledDdpSettingWrapper,
   StyledExpandSetGroup,
   StyledExpandSetting,
   StyledExpandSettingWrapper,
   StyledGridGroup,
   StyledInputLabel,
   StyledLabel,
   StyledLayoutSetting,
   StyledMgButton,
   StyledSettingIcon,
} from "./styles/SettingDDPStyles";
import i18n from "../../../../utils/i18n-utils";
import { StyledInfoIcon, StyledInfoWrapper, StyledKindButton } from "../../styles/EmotionStyles";
import TextTypeField from "../../../input/textfield/TextTypeField";
import { CustomTooltip } from "../../../../../public/resource/js/customTooltip";
import { StyledMenuItem, StyledSelect, StyledSettingContainer } from "./styles/SettomgStyles";
import { QueryKey } from "../../../../redux/reducers/query";
import { RootState } from "../../../../redux/reducers";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { useToastDispatch } from "../../../../context/ToastContext";
import { ButtonType, DDP, DDPSetting, EditMode, Info, SelectCell } from "./types/SettingDDPTypes";
import UserConfigAPI from "../../../../api/UserConfigAPI";
import CommonUtils from "../../../../../public/resource/js/utils/common";
import {
   StyledAlert,
   StyledAlertButtonWrapper,
   StyledAlertContents,
   StyledAlertTitle,
} from "../styles/SettingDalogStyles";
import OkBtn from "../../../button/OkBtn";
import CancelBtn from "../../../button/CancelBtn";

enum SaveButtonType {
   CREATE = "create",
   MODIFY = "modify",
   UPDATE = "update",
}

const width = 320;
const height = 200;

export default function SettingDDP() {
   const ddpCanvasRef = useRef<HTMLCanvasElement>(null);
   const [alertOpen, setAlertOpen] = useState<boolean>(false);
   const [expandType, setExpandType] = useState<string>("smartView");
   // const [modality, setModality] = useState<string>("");
   const [modality, setModality] = useState<Array<string>>([]);
   const [selectedModality, setSelectedModality] = useState<string>("");
   const [bodypart, setBodypart] = useState<Array<string>>([]);
   const [selectedBodypart, setSelectedBodypart] = useState<string>("");
   const [studyDesc, setStudyDesc] = useState<string>("");
   const [saveBtnType, setSaveBtnType] = useState<SaveButtonType>(SaveButtonType.CREATE);
   const [_doubleCheck, set_doubleCheck] = useState<boolean>(false);
   const [viewType, setViewType] = useState<string>("Stack");
   const [isSameModality, setIsSameModality] = useState<boolean>(true);
   const [isMgProtocol, setIsMgProtocol] = useState<boolean>(true);
   const [rowData, setRowData] = useState<any>([]);
   // const [hangingProtocols, setHangingProtocols] = useState<Array<DDPSetting>>([]);
   const [g_row_selected, setG_row_selected] = useState<RowNode>();
   const [_mergeDDPCells, set_mergeDDPCells] = useState<any>({});
   const [_dragCell, set_dragCell] = useState<any>({
      dragSetPosition: [],
      dragSetInsertPosition: [],
   });
   const [modifyWarningDialog, setModifyWarningDialog] = useState<boolean>(false);
   const [oldCnt, setOldCnt] = useState<number>(0);
   const [info, setInfo] = useState<Info>({ width: 320, height: 200, editMode: EditMode.SELECT_CELL });
   const [ddpCanvas, setDdpCanvas] = useState<HTMLCanvasElement>();
   const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
   // const [ddpInfo, setDdpInfo] = useState<DDPInfo>({});
   const [_bInsertPosition, set_bInsertPosition] = useState<boolean>(false);

   /* no set */
   const [e_CellStyle_None, setE_CellStyle_None] = useState<number>(0);
   const [m_sBaseText, setM_sBaseText] = useState<string>("혫");
   const [maxOldFilmCnt, setMaxOldFilmCnt] = useState<number>(1);

   // const [layoutSettingDisabled, setLayoutSettingDisabled] = useState<boolean>(false);
   // const [bodypartDisabled, setBodypartDisabled] = useState<boolean>(false);
   // const [studyDescDisabled, setStudyDescDisabled] = useState<boolean>(false);
   // const [tileDisabled, setTileDisabled] = useState<boolean>(false);
   // const [stackDisabled, setStackDisabled] = useState<boolean>(false);
   // const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);
   // const [clearBtnDisabled, setClearBtnDisabled] = useState<boolean>(false);
   const [modalityDisabled, setModalityDisabled] = useState<boolean>(false);
   const [sameModalityChecked, setSameModalityChecked] = useState<boolean>(false);

   // set_rightCheck(false);
   // setG_drag(false);
   const [_rightCheck, set_rightCheck] = useState<boolean>(false);
   const [g_drag, setG_drag] = useState<boolean>(false);
   const [g_Listchanged, setG_Listchanged] = useState<boolean>(false);
   const userConfig = useSelector((state : RootState) => state.common.userConfig);
   const toastDispatch = useToastDispatch();

   // const onGridReady = (params: { api: any; columnApi: any; }) => {
   //    setGridOptions({ ...gridOptions, api: params.api, columnApi: params.columnApi });
   // };

   const columnDefs : ColDef[] = useMemo(() => [
      { headerName: "ObjectId", field: "id", hide: true },
      { field: "idx", headerName: i18n("label.idx"), width: 60, rowDrag: true, sortable: false, cellRenderer: params => ddpCellRender(params) },
      { field: "modality", headerName: i18n("label.modality"), width: 100, sortable: false },
      { field: "bodypart", headerName: i18n("label.bodypart"), width: 100, sortable: false },
      { field: "viewType", headerName: i18n("label.viewType"), width: 70, sortable: false },
      { field: "studyDescription", headerName: i18n("label.studyDesc"), width: 235, sortable: false },
   ], []);

   /*
   *  https://www.ag-grid.com/react-data-grid/context-menu/
   * */
   const getContextMenuItems = useCallback((params: GetContextMenuItemsParams) : (string | MenuItemDef)[] => {
      if (!params.node) return [];
      params.node.setSelected(true, true);
      const result = [
         {
            name: i18n("label.delete"),
            action() {
               mouseRightClicked(params);
            },
         },
      ];
      return result;
   }, []);

   const [gridOptions, setGridOptions] = useState<GridOptions>({
      defaultColDef: {
         sortable: true,
         suppressMenu: true, // 메뉴 막기
      },
      rowDragManaged: true,
      suppressRowDrag: true,
      components: {
         customTooltip: CustomTooltip,
      },
      tooltipShowDelay: 0,
      animateRows: true,
      overlayNoRowsTemplate: `<span class="ag-overlay-no-rows-center">${i18n("label.noRecordsFound")}</span>`,
      rowSelection: "single",
   });

   const modalityState = useSelector((state: RootState) => state.query[QueryKey.GET_MODALITY_LIST]);
   const bodypartState = useSelector((state: RootState) => state.query[QueryKey.GET_BODYPART_LIST]);

   useDidMountEffect(() => {
      initDDPSetting(userConfig);
   }, [userConfig]);

   useEffect(() => {
      if (userConfig) initDDPSetting(userConfig);
   }, []);

   useEffect(() => {
      if (modalityState?.result) {
         const modality = modalityState.result;
         setModality(modality);
      }
   }, [modalityState]);

   useEffect(() => {
      if (bodypartState?.result) {
         const bodypart = bodypartState.result;
         setBodypart(bodypart);
      }
   }, [bodypartState]);

   useEffect(() => {
      if (saveBtnType !== SaveButtonType.CREATE) set_doubleCheck(true);
      if (saveBtnType === SaveButtonType.UPDATE) setModifyWarningDialog(true);
   }, [saveBtnType]);

   function ddpCellRender(param: any) {
      param.eGridCell.querySelector("span").classList.remove("ag-icon");
      param.eGridCell.querySelector("span").classList.remove("ag-icon-grip");
      param.eGridCell.querySelector("span").classList.add("ag-row-drag1");
      return param.value;
   }

   useEffect(() => {
      const modality = selectedModality;
      new Promise<void>((resolve) => {
         clearDDP(false, !_doubleCheck);
         resolve();
      }).then(() => {
         setIsSameModality(!(modality === "CT" || modality === "MR"));
         setIsMgProtocol(modality !== "MG");
         if (modality === "ALL") {
            // setIsSameModality(true);
            // setIsMgProtocol(true);
            UserConfigAPI.getUserDDPByUserId().then((result) => {
               if (result) setRowData(setUseRowData(result));
            });
         } else {
            UserConfigAPI.getUserDDPsByModality(modality).then((result) => {
               if (result) ddpListRowData(result);
               else ddpListRowData([]);
            });

            // if (modality === "CT" || modality === "MR") setIsSameModality(false);
            // else if (modality === "MG") setIsMgProtocol(false);
         }
      }).then(() => {
         oldCntApply();
      });
   }, [selectedModality]);

   const handleExpandTypeChange = (event: any) => {
      const selectedValue = event.target.value;
      setExpandType(selectedValue);
      let expandValue;
      if (selectedValue === "smartView") expandValue = "T";
      else if (selectedValue === "singleView") expandValue = "F";
      else expandValue = "A";

      updateHangingProtocolExpand(expandValue);
      const expand = JSON.parse(localStorage.getItem("expand")!);
      if (expand) localStorage.setItem("expand", JSON.stringify(expandValue));
   };

   const handleSameModalityCheckedChange = (e: any) => {
      const value = e.target.checked;
      setSameModalityChecked(value);
   };

   function okAlter() {
      mouseDoubleClicked(g_row_selected?.data);
      closeAlert();
   }

   function closeAlert() {
      setAlertOpen(false);
   }

   /**
    * function setUseRowData
    * 사용자가 설정한 HangingProtocol 전체 List
    *
    * Create by minjeong on 2020/08/05 12:31 오후
    * @param {Array} hangingProtocols
    * @return {Array} row
    * */
   function setUseRowData(hangingProtocols: Array<DDPSetting>) {
      if (!hangingProtocols) return hangingProtocols;

      const rows : Array<any> = [];
      hangingProtocols.forEach((hangingProtocol, idx) => {
         const row = {
            id: "",
            idx: 0,
            modality: "",
            bodypart: "",
            studyDescription: "",
            viewType: "",
            layout: "",
            groupType: [""],
         };

         row.id = hangingProtocol.id;
         row.idx = idx + 1;
         row.modality = hangingProtocol.ddp.modality;
         row.bodypart = !hangingProtocol.ddp.bodypart ? "ALL" : hangingProtocol.ddp.bodypart;
         row.studyDescription = hangingProtocol.ddp.studyDescription;
         row.viewType = hangingProtocol.ddp.viewType;
         row.layout = `row: ${hangingProtocol.ddp.layout.x}, col: ${hangingProtocol.ddp.layout.y}`;
         row.groupType = [];

         hangingProtocol.ddp.group.forEach((group) => {
            row.groupType.push(`${group.groupType} Film-${group.seq}`);
         });

         rows.push(row);
      });

      return rows;
   }

   /**
    * function ddpListRowData
    * DDP 설정 List Data
    *
    * Create by 김민정 on 2018-04-27 오전 10:18
    * @return {Array} row
    * @param ddpSettings
    * */
   function ddpListRowData(ddpSettings: Array<DDPSetting>) {
      const row: Array<any> = [];
      if (ddpSettings) {
         for (let i = 0; i < ddpSettings.length; i++) {
            const nDDP = ddpSettings[i];
            const newRowData = {
               id: nDDP.id,
               idx: i + 1,
               modality: nDDP.ddp.modality,
               bodypart: nDDP.ddp.bodypart === "" ? "ALL" : nDDP.ddp.bodypart,
               studyDescription: nDDP.ddp.studyDescription,
               viewType: nDDP.ddp.viewType,
               groupType: [],
            };

            for (let j = 0; j < nDDP.ddp.group.length; j++) {
               const nGroup = nDDP.ddp.group[j];
               // @ts-ignore
               newRowData.groupType.push(`${nGroup.groupType} Film-${nGroup.seq}`);
            }

            if ((_doubleCheck && !_rightCheck) || g_drag) { // DDP 생성
               gridOptions.api?.forEachNode((rowNode) => {
                  const { data } = rowNode;
                  if (data.id === newRowData.id) {
                     data.idx = newRowData.idx;
                     data.modality = newRowData.modality;
                     data.bodypart = newRowData.bodypart;
                     data.studyDescription = newRowData.studyDescription;
                     data.viewType = newRowData.viewType;
                     data.groupType = newRowData.groupType;
                     // @ts-ignore
                     gridOptions.api?.redrawRows({ data });
                  }
               });
            }
            row.push(newRowData);
         }
         setRowData(row);
      }
   }

   function getLocalStorageHangingProtocol(ddps:Array<any> = [], modality = "", dragIdx = 1) {
      // idx가 1인 hangingprotocol find
      const ddp = ddps.find(ddp => ddp.ddp.idx === 1);
      if (ddp) {
         const userId = JSON.parse(localStorage.getItem("user")!).id;
         const str = { userId, display: [ddp.ddp] };
         const hangingProtocols = JSON.parse(localStorage.getItem("hangingProtocols")!);

         // dragIdx = drag로 순서 변경시 idx가 1인지 체크
         if (dragIdx === 1) {
            // localStorage item 최초 생성
            if (hangingProtocols?.userId !== userId) { // hangingProtocols localStorage 값이 없거나 값이 있는데 다른 사용자의 데이터인 경우 새로 설정
               localStorage.setItem("hangingProtocols", JSON.stringify(str));
            } else {
               const modalIdx = hangingProtocols.display.findIndex((l: { modality: string; }) => l.modality === modality);

               // localStorage에 저장되어있는 Modality를 찾은 뒤 없으면 생성 있으면 변경해준다.
               if (modalIdx > -1) hangingProtocols.display[modalIdx] = ddp.ddp;
               else hangingProtocols.display.push(ddp.ddp);

               localStorage.setItem("hangingProtocols", JSON.stringify(hangingProtocols));
            }
         }
      }
   }

   function openToast(msg: string, isErr: boolean) {
      toastDispatch({ type: "SET_TOAST", open: true, msg, isErr });
   }

   function setHangingProtocol() {
      if (saveBtnType === SaveButtonType.MODIFY) {
         setSaveBtnType(SaveButtonType.UPDATE);
         settingLayout();
         setModalityDisabled(true);
         setInfo(prevState => ({
            ...prevState,
            editMode: EditMode.SELECT_CELL,
         }));
         return;
      }

      if (!_doubleCheck) saveDDP();
      else updateDDP();
   }

   /**
    * function removeDuplicatesArray
    * 중복된 배열 제거
    *
    * Create by 김민정 on 2018-06-18 오후 7:03
    * @param {} arr
    * @return {} removeArr
    * */
   function removeDuplicatesArray(arr: Array<any>) {
      const removeArr = [];
      for (let i = 0; i < arr.length; i++) {
         if (removeArr.length === 0) {
            removeArr.push(arr[i]);
         } else {
            let duplicatesFlag = true;
            for (let j = 0; j < removeArr.length; j++) {
               if (removeArr[j] === arr[i]) {
                  duplicatesFlag = false;
                  break;
               }
            }
            if (duplicatesFlag) {
               removeArr.push(arr[i]);
            }
         }
      }

      return removeArr;
   }

   /**
    * function oldStringSort
    * Old 필름 Count 갯수를 제외한 문자
    * Old1->Old, Old2->Old...
    *
    * Create by 김민정 on 2018-05-03 오전 10:52
    * @param {String} oldBtn
    * @return {String} strOld
    * */
   function oldStringSort(oldBtn: string) {
      return oldBtn.substring(0, 3);
   }

   /**
    * function oldCntSort
    * Old 필름 Count 갯수
    *
    * Create by 김민정 on 2018-05-03 오전 9:34
    * @param {String} oldBtn
    * @return {Number} nCnt
    * */
   function oldCntSort(oldBtn: string) {
      return oldBtn.substring(3, oldBtn.length);
   }

   /**
    * function groupSetting
    * ddp group 설정 정보
    *
    * Create by 김민정 on 2018-04-11 오후 5:14
    * @param {}
    * @return {Object} group
    * */
   function groupSetting() {
      if (!info.setPosition) return null;
      const group: any = []; // return 하는 group 값
      const group_new: any = {};
      const group_old: any = {};
      // const group_first = {};

      group_new.position = [];
      group_old.seq = [];
      group_old.position = [];
      // group_first.position = [];

      const g_group: any = {}; // old Group
      if (info.setPosition.cells.length > 0) {
         const dragSetPosition: Array<any> = removeDuplicatesArray(_dragCell.dragSetPosition);
         for (let i = 0; i < dragSetPosition.length; i++) {
            const nIndexDragSetPosition = dragSetPosition[i];
            if (nIndexDragSetPosition.command) {
               // if (dragSetPosition[nIndex].command !== undefined && dragSetPosition[nIndex].columnApi !== "") { }
               const strOld = oldStringSort(nIndexDragSetPosition.command);
               const pos = { groupPos: `${String(nIndexDragSetPosition.row)},${String(nIndexDragSetPosition.col)}` };
               if (nIndexDragSetPosition.command === "New") {
                  group_new.groupType = "New";
                  group_new.seq = "1";
                  group_new.position.push(pos);
               }

               if (["Old1", "Old2", "Old3", "Old"].includes(strOld)) {
                  const nOldCnt = oldCntSort(nIndexDragSetPosition.command);
                  if (!g_group[nOldCnt]) { // Old1, Old2...의 첫 Position
                     const firstPosition = [];
                     firstPosition.push(pos);
                     g_group[nOldCnt] = firstPosition;
                  } else { // Old1, Old2...의 그 다음 Position 들
                     const nextPosition = g_group[nOldCnt];
                     nextPosition.push(pos);
                     g_group[nOldCnt] = nextPosition;
                  }
               }
            }
         }
      }
      else { // layout 미변경
         for (let i = 0; i < _dragCell.dragSetInsertPosition.length; i++) {
            const nIndexDragSetInsertPosition =  _dragCell.dragSetInsertPosition[i];
            const pos = { groupPos: `${String(nIndexDragSetInsertPosition.row)},${String(nIndexDragSetInsertPosition.col)}` };
            if (nIndexDragSetInsertPosition.command === "New") {
               group_new.groupType = nIndexDragSetInsertPosition.command;
               group_new.seq = nIndexDragSetInsertPosition.seq;
               group_new.position.push(pos);
            }

            else if (nIndexDragSetInsertPosition === "Old") {
               const nOldCnt = nIndexDragSetInsertPosition.seq;
               if (!g_group[nOldCnt]) { // Old1, Old2...의 첫 Position
                  const firstPosition = [];
                  firstPosition.push(pos);
                  g_group[nOldCnt] = firstPosition;
               }
               else { // Old1, Old2...의 그 다음 Position 들
                  const nextPosition = g_group[nOldCnt];
                  nextPosition.push(pos);
                  g_group[nOldCnt] = nextPosition;
               }
            }
         }
      } // end of else

      if (group_new.position.length > 0) group.push(group_new);
      if (Object.keys(g_group).length > 0) {
         for (let i = 1; i <= oldCnt; i++) {
            if (g_group[i]) {
               const oldGroup = {
                  groupType: "Old",
                  position: g_group[i],
                  seq: String(i),
               };
               group.push(oldGroup);
            }
         }
      }

      return group;
   }

   function checkedLayout() {
      if (!info.setPosition) return false;
      let bChkLayout = false;
      for (let i = 0; i < info.setPosition.cells.length; i++) {
         if (!info.setPosition.cells[i].command) {
            bChkLayout = true;
            break;
         }
      }

      return bChkLayout;
   }

   /**
    * function saveDDPSetting
    * ddp 설정 정보
    *
    * Create by 김민정 on 2018-04-04 오후 5:34
    * @param {}
    * @return {Object} ddp
    * */
   function saveDDPSetting() {
      if (!info.selectCell) return null;
      const ddp: any = {
         modality: selectedModality,
         idx: -1,
         bodypart: (selectedBodypart === "ALL") ? "" : selectedBodypart,
         viewType,
         studyDescription: studyDesc,
         // group: group ? [groupSetting()[0]] : null,
         group: [],
         layout: {
            x: -1,
            y: -1,
         },
      };
      if (_mergeDDPCells && _doubleCheck) {  // DDP 변경
         ddp.idx = g_row_selected?.data.idx;
         if (info.selectCell.selectedRow === -1) { // layout 변경을 하지 않았을때
            ddp.layout.x = _mergeDDPCells.x;
            ddp.layout.y = _mergeDDPCells.y;
         }
         else { // layout 변경을 했을때
            ddp.layout.x = _dragCell.rows;
            ddp.layout.y = _dragCell.columns;
         }
      }
      else { // DDP 생성
         // layout 설정을 하지 않았을때
         if (info.selectCell.selectedRow === -1) return null;

         ddp.idx = 0;
         ddp.layout.x = String(info.selectCell.selectedRow + 1);
         ddp.layout.y = String(info.selectCell.selectedColumn + 1);
      }

      // ddp.group.push(groupSetting());
      // ddp.group = ddp.group[0];

      const ddpGroup = [];
      ddpGroup.push(groupSetting()[0]);
      ddp.group.push(ddpGroup[0]);

      if (ddp.group?.length) return ddp;
      return null;
   }

   /**
    * function oldCntApply
    * Old Film 갯수 적용 버튼 ( Apply )
    *
    * Create by 김민정 on 2018-05-16 오전 11:11
    * @param {}
    * @return {}
    * */
   function oldCntApply() {
      setOldCnt(maxOldFilmCnt);

      if (!_bInsertPosition) {
         set_dragCell((prevState: any) => ({
            ...prevState,
            dragSetPosition: [],
         }));
      }

      startBody();
   }

   function saveDDP() {
      if (selectedModality === "ALL") {
         openToast("Please select a modality or double-click a form in the list below.", true);
         return;
      }

      const saveDDP = saveDDPSetting();
      if (!saveDDP && selectedModality !== "ALL") {
         openToast("Please set up the layout first.", true);
         return;
      }
      if (checkedLayout()) {
         openToast("Please Complete Hanging Protocols Layout Setting.", true);
         return;
      }

      const multipleDDPs = [saveDDP];
      if (sameModalityChecked && (selectedModality === "CT" || selectedModality === "MR")) {
         const multipleModality = { ...saveDDP };
         if (selectedModality === "CT") multipleModality.modality = "MR";
         else if (selectedModality === "MR") multipleModality.modality = "CT";
         multipleDDPs.push(multipleModality);
      }

      multipleDDPs.forEach((l) => {
         UserConfigAPI.createDDPSetting(l).then((result) => {
            if (result) {
               UserConfigAPI.getUserDDPsByModality(l.modality).then((ddps) => {
                  if (ddps) {
                     // 전체 idx sort
                     const newDdps: Array<DDPSetting> = [...ddps];
                     for (let i = 0; i < newDdps.length; i++) {
                        newDdps[i].ddp.idx++;
                        UserConfigAPI.updateUserDDPById(newDdps[i].id, newDdps[i].ddp);
                     }
                     // ddps.forEach((obj) => {
                     //    newDdps = {
                     //       ...obj,
                     //    }
                     //    obj.ddp.idx += 1;
                     //    UserConfigAPI.updateUserDDPById(obj.id, obj.ddp);
                     // });

                     if (selectedModality === l.modality) {
                        // setHangingProtocols(ddps);
                        // grid redraw
                        ddpListRowData(ddps);
                        openToast("Hanging Protocols created successfully.", false);
                     }
                     // localStorage : create
                     // localStorage : multiple create (CT/MR)
                     Promise.all(ddps).then(v => getLocalStorageHangingProtocol(v, l.modality));
                  }
               }).catch((reason) => {
                  console.log(reason);
               });

               setG_Listchanged(true);
               clearDDP(false, true);
            }
         }).catch((reason) => {
            console.log(reason);
         });
      });
   }

   /**
    * function clearDDP
    * DDP 설정 초기화
    *
    * Create by 김민정 on 2018-05-03 오후 5:08
    * */
   function clearDDP(isSelectedModalityClear: boolean, isSaveBtnClear: boolean, modality?: string) {
      // g_Listchanged = false -> modality dropdownList 값 초기화
      // g_Listchanged = true -> modality dropdownList 값 초기화 안함
      // if (!g_Listchanged && !_rightCheck && !_doubleCheck) {
      // if (this.modality && !this.modality[0]) this.$modalityList.selected = 1;
      // else this.$modalityList.selected = 0;
      // }

      if (isSelectedModalityClear) setSelectedModality("ALL");
      if (isSaveBtnClear) {
         setSaveBtnType(SaveButtonType.CREATE);
         setSelectedBodypart("ALL");
         setStudyDesc("");
         setViewType("Stack");
      }
      // setLayoutSettingDisabled(false);
      // setBodypartDisabled(false);
      // setStudyDescDisabled(false);
      // setTileDisabled(false);
      // setStackDisabled(false);
      // setSaveBtnDisabled(false);
      // setClearBtnDisabled(false);
      set_bInsertPosition(false);
      set_doubleCheck(false);
      set_rightCheck(false);
      setG_drag(false);
      set_dragCell((prevState: any) => ({
         ...prevState,
         dragSetPosition: [],
         dragSetInsertPosition: [],
      }));
      setInfo(prevState => ({
         ...prevState,
         mouseDragCellIndex: undefined,
      }));

      /*
      * row delete 시 selectedModality 값이 없음
      * 주석 후 selectedModality hook 에서 변경
      * */
      // if (selectedModality !== "CT" && selectedModality !== "MR") setIsSameModality(true);

      setSameModalityChecked(false);
      setModalityDisabled(false);
      if (g_row_selected) g_row_selected.setSelected(false, false, false);
      startBody();
   }

   /**
    * function updateDDP
    * ddp설정 변경
    *
    * Create by 김민정 on 2018-05-24 오후 3:11
    * @param {}
    * @return {}
    * */
   function updateDDP() {
      const multipleDDPs = saveDDPSetting();
      if (!multipleDDPs || !multipleDDPs?.group[0]) {
         openToast("Please Complete Hanging Protocols Layout Setting multipleDDPs.",  true);
         return;
      }
      if (checkedLayout()) {
         openToast("Please Complete Hanging Protocols Layout Setting.",  true);
         return;
      }

      if (!g_row_selected) return;
      const row = g_row_selected.data;
      UserConfigAPI.getUserDDPsByModality(selectedModality).then((result) => {
         UserConfigAPI.updateUserDDPById(row.id, multipleDDPs).then((ddp) => {
            if (result) {
               const ddps = [...result];
               for (let i = 0; i < ddps.length; i++) {
                  if (ddps[i].id === ddp.id) {
                     ddps[i].ddp = ddp.ddp;
                     openToast("Hanging Protocols updated successfully.", false);
                  }
               }

               // update 후 sort
               ddps.sort((a, b) => (a.ddp.idx < b.ddp.idx ? -1 : 1));

               // setHangingProtocols(ddps);
               ddpListRowData(ddps);

               // localStorage : update
               getLocalStorageHangingProtocol([ddp], selectedModality);
               setG_Listchanged(true);
               clearDDP(false, true);
            }
         });
      });

      // CT, MR 경우 생성
      if (sameModalityChecked && (selectedModality === "CT" || selectedModality === "MR")) {
         // ddp 구조체 복사
         const multipleModality = { ...multipleDDPs };
         multipleModality.idx = 0;
         if (selectedModality === "CT") multipleModality.modality = "MR";
         else if (selectedModality === "MR") multipleModality.modality = "CT";

         UserConfigAPI.createDDPSetting(multipleModality).then((result) => {
            if (result) {
               UserConfigAPI.getUserDDPsByModality(multipleModality.modality).then((ddp) => {
                  if (ddp) {
                     const newDDP = ddp;
                     for (let i = 0; i < newDDP.length; i++) {
                        newDDP[i].ddp.idx += 1;
                        UserConfigAPI.updateUserDDPById(newDDP[i].id, newDDP[i].ddp);
                     }

                     getLocalStorageHangingProtocol(ddp, multipleModality.modality);
                  }
               }).catch((reason) => {
                  console.log(reason);
               });
            }
         }).catch((reason) => {
            console.log(reason);
         });
      }
   }

   /**
    * function mouseRightClicked
    * 마우스 우클릭 이벤트
    * DDP DELETE - DDP 셋팅 정보 삭제
    *
    * Create by 김민정 on 2018-06-05 오전 9:39
    * @param {}
    * @return {}
    * */
   function mouseRightClicked(e: any) {
      if (e.node) {
         const { id, modality } = e.node.data;
         // const pModalityList : Array<string> = e.context.modalityList;
         // let newSelectIndex = 0;
         //
         // console.log(`pModalityList: ${JSON.stringify(pModalityList)}`);
         // pModalityList.forEach((element, index) => {
         //    if (element === modality) newSelectIndex = index;
         // });

         UserConfigAPI.deleteUserDDPByUserId(id).then((result) => {
            if (result) {
               UserConfigAPI.getUserDDPsByModality(modality).then((ddps) => {
                  const newDdps = ddps || [];
                  if (ddps !== null) {
                     for (let i = 0; i < newDdps.length; i++) {
                        newDdps[i].ddp.idx = i;
                        UserConfigAPI.updateUserDDPById(newDdps[i].id, newDdps[i].ddp);
                     }
                  }
                  // setHangingProtocols(newDdps);
                  // grid
                  ddpListRowData(newDdps);
                  setSelectedModality(modality);

                  // localStorage : delete
                  getLocalStorageHangingProtocol(newDdps, modality);
               }).catch((reason) => {
                  console.log(reason);
               });

               setG_Listchanged(true);
               clearDDP(false, true);
            }
         }).catch((reason) => {
            console.log(reason);
         });
      }
   }

   /**
    * function setDDPPositionCell
    * layout에 따른 cell 계산
    *
    * Create by 김민정 on 2018-05-15 오후 4:05
    * @return {}
    * @param row
    * @param column
    * */
   function setDDPPositionCell(row: number, column: number) {
      const ddpCells = [];
      const nWholeWidth = 320 - (5 * 2);
      const nWholeHeight = 200 - 5 - 5;

      const nOneWidth = parseInt(String(nWholeWidth / column), 10);
      const nOneHeight = parseInt(String(nWholeHeight / row), 10);

      for (let i = 0; i < row; i++) {
         for (let j = 0; j < column; j++) {
            const nX = (j * nOneWidth) + 5;
            const nY = (i * nOneHeight) + 5;
            const cellInfo = {
               row: i,
               col: j,
               position: newPoint(nX, nY),
               size: newSize(nOneWidth, nOneHeight),
               layout: `${i},${j}`,
               command: "",
               seq: "",
               sortNo: 0,
               no: 0,
               imgPos: "",
               commandBackColors: "",
            };

            ddpCells.push(cellInfo);
         }// for j
      }// for i

      return ddpCells;
   }

   /**
    * function setDDPCellMerge
    * ddp Cell 정보 Merge
    *
    * Create by 김민정 on 2018-05-15 오후 4:46
    * @param ddp
    * @return {}
    * */
   function setDDPCellMerge(ddp: DDP) {
      set_dragCell((prevState: any) => ({
         ...prevState,
         dragSetInsertPosition: [],
      }));
      const ddpCells = setDDPPositionCell(ddp.layout.x, ddp.layout.y);
      const ddpGroup = [];
      let nCnt = 1; // sortNo

      // Cell
      for (let i = 0; i < ddp.group.length; i++) {
         for (let j = 0; j < ddp.group[i].position.length; j++) {
            const cellInfo = {
               command: ddp.group[i].groupType,
               seq: ddp.group[i].seq,
               imgPos: ddp.group[i].position[j].imagePos ?? null,
               layout: ddp.group[i].position[j].groupPos,
               position: ddp.group[i].position,
               sortNo: j + 1,
               no: nCnt,
            };

            ddpGroup.push(cellInfo);
            nCnt++;
         }
      }

      // Merge
      for (let i = 0; i < ddpGroup.length; i++) {
         const group = ddpGroup[i];
         for (let j = 0; j < ddpCells.length; j++) {
            const cell = ddpCells[j];
            if (cell.layout === group.layout) {
               cell.command = group.command;
               cell.seq = group.seq;
               cell.sortNo = group.sortNo;
               cell.no = group.no;
               // cell.position = ddp
               if (group.imgPos) cell.imgPos = group.imgPos;
               if (cell.no) {
                  set_dragCell((prevState: any) => ({
                     dragSetInsertPosition: [...prevState.dragSetInsertPosition, cell],
                  }));
               }
               cell.commandBackColors = "103,153,255";

               // Cell Color
               // if (cell.command === "New") cell.commandBackColors = "103,153,255";
               if (cell.command === "Old") cell.commandBackColors = "134,229,127";

               break;
            }
         }
      }

      set_dragCell((prevState: any) => ({
         dragSetInsertPosition: prevState.dragSetInsertPosition.sort((a: { no: number; }, b: { no: number; }) => a.no - b.no),
      }));

      set_mergeDDPCells((prevState: any) => ({
         // ...prevState,
         ddpCells,
         x: ddp.layout.x,
         y: ddp.layout.y,
      }));
   }

   /**
    * function setDDPRedraw
    * 마우스 더블클릭, MG선택 시 설정 viewing
    *
    * Create by minjeong on 24/09/2019 9:36 오전
    * */
   async function setDDPRedraw(id: string, result: Array<DDPSetting>) {
      for (let i = 0; i < result.length; i++) {
         const ddps = result[i];
         if (ddps.id === id) {
            setSelectedModality(ddps.ddp.modality);

            if (ddps.ddp.bodypart === "") setSelectedBodypart("ALL");
            else setSelectedBodypart(ddps.ddp.bodypart);

            setViewType(ddps.ddp.viewType);
            setStudyDesc(ddps.ddp.studyDescription);
            setDDPCellMerge(ddps.ddp); // cell 정보

            // modify mode state
            set_dragCell((prevState: any) => ({
               ...prevState,
               dragSetPosition: [],
            }));
            set_bInsertPosition(true);
            set_doubleCheck(true);
            setSaveBtnType(SaveButtonType.MODIFY);

            // oldCntApply(); // draw

            setG_Listchanged(false);

            // console.log(`setDDPRedraw _bInsertPosition: ${_bInsertPosition}`);
            // oldCntApply(); // draw | react hook redraw
         }
      }
   }

   /**
    * function mouseDoubleClicked
    * 마우스 더블클릭 이벤트
    * DDP SET PASTE - DDP 셋팅 정보 불러오기
    *
    * Create by 김민정 on 2018-06-05 오전 9:39
    * @param data
    * @return {}
    * */
   function mouseDoubleClicked(data: any) {
      if (data) {
         setModalityDisabled(false);
         const { id, modality } = data;

         UserConfigAPI.getUserDDPsByModality(modality).then(async (result) => {
            if (result) {
               setInfo(prevState => ({
                  ...prevState,
                  editMode: EditMode.SET_INSERT_POSITION,
               }));
               await setDDPRedraw(id, result).then(() => {
                  ddpListRowData(result);
               });
               // ddpListRowData(result);
            }
         }).catch((reason) => {
            console.log(reason);
         });
      }
   }

   /*  this.$.modalityList.addEventListener("selected-item-changed" ... */
   function handleSelectedModalityChange(event: any) {
      const { value } = event.target;
      setG_Listchanged(true);

      if (value) {
         setSelectedModality(value);
         setIsSameModality(true);
         setIsMgProtocol(true);
         if (value === "ALL") {
            // gridOptions.rowDragManaged = false;
            setGridOptions(prevState => ({
               ...prevState,
               rowDragManaged: false,
            }));
            gridOptions.api?.setSuppressRowDrag(true);

            UserConfigAPI.getUserDDPByUserId().then((result) => {
               if (result) {
                  // setHangingProtocols(result);
                  setRowData(setUseRowData(result));
               }
               else {
                  // setHangingProtocols([]);
                  setRowData([]);
               }
            });
         }
         else {
            // gridOptions.rowDragManaged = true;
            setGridOptions(prevState => ({
               ...prevState,
               rowDragManaged: true,
            }));
            gridOptions.api?.setSuppressRowDrag(false);

            // same modality checkbox 활성화
            if (selectedModality === "CT" || selectedModality === "MR") setIsSameModality(false);
            // add default mg protocol button 활성화
            if (selectedModality === "MG") setIsMgProtocol(false);

            UserConfigAPI.getUserDDPsByModality(selectedModality).then((result) => {
               if (result) {
                  // setHangingProtocols(result);
                  setRowData(setUseRowData(result));
               }
               else {
                  // setHangingProtocols([]);
                  setRowData([]);
               }
            });
         }

         oldCntApply();
      }
   }

   function handleSelectedBodypartChange(event: any) {
      const { value } = event.target;

      if (value) {
         setSelectedBodypart(value);
         //   ...
      }
   }

   function convertExpandType(value: string) {
      switch (value) {
      case "T": setExpandType("smartView"); break;
      case "F": setExpandType("singleView"); break;
      case "A": setExpandType("multiView"); break;
      default: setExpandType(value);
      }
   }

   function initDDPSetting(s: any) {
      if (s && s.filmbox && s.filmbox.expand !== undefined) {
         const userId = JSON.parse(localStorage.getItem("user") || "{}").id;
         const expand = JSON.parse(localStorage.getItem("expand")!);
         if (userId === s.userId && !expand) localStorage.setItem("expand", JSON.stringify(s.filmbox.expand));

         if (expand) convertExpandType(expand);
         else convertExpandType(s.filmbox.expand);
      }

      // if (modalityList) setModality(modalityList);
      // else setRowData([]);
      //
      // if (bodypartList) setBodypart(bodypartList);

      setSelectedModality("ALL");
   }

   function handleViewTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
      const { value } = e.target;
      setViewType(value);
   }

   function handleStudyDescChange(e: React.ChangeEvent<HTMLInputElement>) {
      const { value } = e.target;
      setStudyDesc(value);
   }

   /*
   * @Canvas
   * */
   useEffect(() => {
      if (!ddpCanvasRef.current) return;
      const canvas = ddpCanvasRef.current;
      setDdpCanvas(canvas);
      setCtx(canvas.getContext("2d"));
      // if (_bInsertPosition) {
      //    setDdpInfo((prevState: DDPInfo) => ({
      //       ...prevState,
      //       editMode: EditMode.SET_INSERT_POSITION,
      //    }));
      // }
   }, [ddpCanvasRef]);

   useEffect(() => {
      if (info) {
         setSelectCellBounds();
         if (info.editMode === EditMode.SELECT_CELL) drawBoard();
      }
   }, [info.selectCell]);

   useEffect(() => {
      if (_mergeDDPCells) drawInsertSetPosition();
   }, [_mergeDDPCells]);

   useEffect(() => {
      if (info.editMode === EditMode.SELECT_CELL) {
         InitializeInfoSelectCell();
      }
      else if (info.editMode === EditMode.SET_POSITION) {
         // SetMouseUp_SetPosition();
         setSetPositionCellBounds();
      }
      drawBoard();
   }, [info.editMode]);

   useEffect(() => {
      if (!info.setPosition) InitializeInfoSetPosition();
      drawBoard();
   }, [info.setPosition]);

   useEffect(() => {
      if (ddpCanvas) {
         initializeInfo();
         InitializeInfoSetPosition();
         InitializeInfoSelectCell();
      }
   }, [ddpCanvas]);

   function initializeInfo() {
      setInfo(prevState => ({
         ...prevState,
         editMode: _bInsertPosition ? EditMode.SET_INSERT_POSITION : EditMode.SELECT_CELL,
         width,
         height,
      }));

      InitializeInfoSelectCell();
      InitializeInfoSetPosition();
   }

   function InitializeInfoSelectCell() {
      const selectCell: SelectCell = {
         rows: 10,
         columns: 10,
         selectedRow: -1,
         selectedColumn: -1,
         selectedIndex: -1,
         backColor: "rgba(0,0,0,0.8)",
         borderColor: "rgba(210, 210, 210, 0.7)",
         selectedBackColor: "rgba(210, 210, 210, 0.2)",
         textFont: "100px Verdana bold",
         textColor: "rgba(210, 210, 210, 0.7)",
         cells: [],
      };

      setInfo(prevState => ({
         ...prevState,
         selectCell,
      }));
      setSelectCellBounds();
   }

   function InitializeInfoSetPosition() {
      const obj: any = {
         commandBackColors: {
            New: "",
            Old: [],
         },
         paddingWidth: 5,
         gapHeight: 3,
         buttonGapWidth: 2,
         buttons: [],
         rows: 5,
         columns: 5,
         command: "",
         foreColor: "rgba(210, 210, 210, 0.7)",
         backColor: "rgba(0,0,0,0.8)",
         borderColor: "rgba(210, 210, 210, 0.7)",
         selectedBackColor: "rgba(0,0,0,0.7)",
         textFont: "12px Verdana",
         textColor: "rgba(210, 210, 210, 0.7)",
         drags: [],
         mouseDragCellIndex: -1,
         cells: [],
      };

      obj.commandBackColors.New = "103,153,255";
      for (let i = 1; i < oldCnt; i++) {
         obj.commandBackColors.Old.push("134,229,127");
      }

      for (let i = 0; i < obj.cells.length; i++) {
         obj.cells[i].command = "";
         obj.cells[i].sortNo = 0;
      }

      setInfo(prevState => ({
         ...prevState,
         setPosition: obj,
      }));
      setSetPositionCellBounds();
      setSetPositionCheck("New");
   }

   function setSetPositionCellBounds() {
      if (info.width < 1 || info.height < 1) return;
      if (info.editMode !== EditMode.SET_POSITION) return;

      const newInfo = info;
      if (newInfo.setPosition) {
         const nStartCellX = newInfo.setPosition.paddingWidth;
         let nStartCellY = newInfo.setPosition.paddingWidth;
         if (newInfo.setPosition.buttons.length) {
            let nBtnX = nStartCellX;
            const nBtnY = nStartCellY;

            for (let i = 0; i < newInfo.setPosition.buttons.length; i++) {
               newInfo.setPosition.buttons[i].position.x = nBtnX;
               newInfo.setPosition.buttons[i].position.y = nBtnY;
               nBtnX = newInfo.setPosition.buttons[i].position.x + newInfo.setPosition.buttons[i].size.width + newInfo.setPosition.buttonGapWidth;
            }
            nStartCellY = nStartCellY + newInfo.setPosition.buttonGapWidth + GetButtonsHeight();
         }
         newInfo.setPosition.cells = [];
         const nRow = newInfo.setPosition.rows;
         const nCol = newInfo.setPosition.columns;

         const nWholeWidth = newInfo.width - (newInfo.setPosition.paddingWidth * 2);
         const nWholeHeight = newInfo.height - newInfo.setPosition.paddingWidth - nStartCellY;

         const nOneWidth = parseInt(String(nWholeWidth / nCol), 10);
         const nOneHeight = parseInt(String(nWholeHeight / nRow), 10);

         for (let i = 0; i < nRow; i++) {
            for (let j = 0; j < nCol; j++) {
               const nX = (j * nOneWidth) + nStartCellX;
               const nY = (i * nOneHeight) + nStartCellY;
               const row = i;
               const col = j;
               const command = newInfo.setPosition.cells[(row * nCol) + (col + 1)]?.command;
               const cellInfo = {
                  row,
                  col,
                  position: newPoint(nX, nY),
                  size: newSize(nOneWidth, nOneHeight),
                  sortNo: (row * nCol) + (col + 1),
                  operation: `(${row} * ${nCol}) + (${col} + 1)`,
                  command: command ?? "New",
               };
               newInfo.setPosition.cells.push(cellInfo);
            }
         }
      }

      setInfo(prevState => ({
         ...prevState,
         setPosition: newInfo.setPosition,
      }));
   }

   function GetButtonsHeight() {
      let nHeight = 0;
      if (info.setPosition) {
         for (let i = 0; i < info.setPosition.buttons.length; i++) {
            nHeight = Math.max(nHeight, info.setPosition.buttons[1].size.height);
         }
      }
      else if (info.insertSetPosition) {
         for (let i = 0; i < info.insertSetPosition.buttons.length; i++) {
            nHeight = Math.max(nHeight, info.insertSetPosition.buttons[1].size.height);
         }
      }

      return nHeight;
   }

   function newPoint(nX: number, nY: number) {
      return { x: nX, y: nY };
   }

   function newSize(nWidth: number, nHeight: number) {
      return { width: nWidth, height: nHeight };
   }

   function setSetPositionCheck(command: string) {
      // @ts-ignore
      const newInfo : Info = CommonUtils.cloneObject(info);
      if (newInfo.setPosition) {
         const { setPosition } = newInfo;
         for (let i = 0; i < setPosition.buttons.length; i++) {
            const button = setPosition.buttons[i];
            if (button.command === command) {
               button.checked = true;
               setPosition.command = command;
            }
            else button.checked = false;
         }

         setInfo(prevState => ({
            ...prevState,
            setPosition: newInfo.setPosition,
         }));
      }
   }

   function setSelectCellBounds() {
      if (info.width < 1 || info.height < 1) return;
      if (info.editMode !== EditMode.SELECT_CELL) return;
      if (!info.selectCell) return;

      const { selectCell } = info;
      const nRow = info.selectCell.rows;
      const nCol = info.selectCell.columns;
      const nOneWidth = (info.width - 1) / nCol;
      const nOneHeight = (info.height - 1) / nRow;
      const cells = [];
      for (let i = 0; i < nRow; i++) {
         for (let j = 0; j < nCol; j++) {
            const nX = j * nOneWidth;
            const nY = i * nOneHeight;
            const cellInfo = {
               x: i,
               y: j,
               row: i,
               col: j,
               cellStyle: e_CellStyle_None,
               position: newPoint(nX, nY),
               size: newSize(nOneWidth, nOneHeight),
               include: info.selectCell.cells[10 * i + j]?.include ?? false,
            };
            cells.push(cellInfo);
         }
      }
      selectCell.cells = cells;
      setInfo(prevState => ({
         ...prevState,
         selectCell,
      }));
   }

   function drawSelectCell() {
      if (ctx) {
         if (!info.selectCell) return;
         for (let i = 0; i < info.selectCell.cells.length; i++) {
            const cells = info.selectCell.cells[i];
            const nX = cells.position.x;
            const nY = cells.position.y;
            const nW = cells.size.width;
            const nH = cells.size.height;

            ctx.fillStyle = cells.include ? info.selectCell.selectedBackColor : info.selectCell.backColor;
            ctx.fillRect(nX, nY, nW, nH);
         }
         const text = `${info.selectCell.selectedRow + 1}X${info.selectCell.selectedColumn + 1}`;
         ctx.beginPath();
         ctx.font = info.selectCell.textFont;
         ctx.fillStyle = info.selectCell.textColor;
         ctx.textAlign = "left";
         ctx.textBaseline = "top";

         const textWidth = ctx.measureText(text).width;
         const textHeight = ctx.measureText(m_sBaseText).width + ctx.measureText(m_sBaseText).width / 2;

         const textX = (info.width - textWidth) / 2;
         const textY = (info.height - textHeight);
         ctx.fillText(text, textX, textY);

         for (let i = 0; i < info.selectCell.cells.length; i++) {
            const nX = info.selectCell.cells[i].position.x;
            const nY = info.selectCell.cells[i].position.y;
            const nW = info.selectCell.cells[i].size.width;
            const nH = info.selectCell.cells[i].size.height;

            ctx.beginPath();
            ctx.strokeStyle = info.selectCell.borderColor;
            ctx.strokeRect(nX + 0.5, nY + 0.5, nW, nH);
         }
      }
   }

   function drawSetPosition() {
      // @ts-ignore
      const newInfo: Info = CommonUtils.cloneObject(info);
      if (!newInfo.setPosition || !ctx) return;
      // Draw Buttons
      const buttonsLength = newInfo.setPosition.buttons.length ?? Object.keys(newInfo.setPosition.buttons).length;
      for (let i = 0; i < buttonsLength; i++) {
         const oBtn = newInfo.setPosition.buttons[i];
         const nX = oBtn.position.x;
         const nY = oBtn.position.y;
         const nW = oBtn.size.width;
         const nH = oBtn.size.height;
         let cBackColor = oBtn.normal.backColor;
         let cForeColor = oBtn.normal.foreColor;
         let cBorderColor = oBtn.normal.borderColor;
         let cUnderColor = oBtn.normal.underColor;

         if (oBtn.style === ButtonType.RADIO && oBtn.checked) {
            cBackColor = oBtn.check.backColor;
            cForeColor = oBtn.check.foreColor;
            cBorderColor = oBtn.check.borderColor;
            cUnderColor = oBtn.check.underColor;
            cUnderColor = `rgba(${newInfo.setPosition.commandBackColors[oBtn.command]},1)`;
         }

         ctx.fillStyle = cBackColor;
         ctx.fillRect(nX, nY, nW, nH);

         const nUnderY = nY + nH - 3;
         ctx.fillStyle = cUnderColor;
         ctx.fillRect(nX + 0.5, nUnderY + 0.5, nW, 3);

         const sText = oBtn.title;
         ctx.beginPath();
         ctx.font = oBtn.textFont;
         ctx.fillStyle = cForeColor;
         ctx.textAlign = "left";
         ctx.textBaseline = "top";
         const nTextW = ctx.measureText(sText).width;
         const nTextH = ctx.measureText(m_sBaseText).width + ctx.measureText(m_sBaseText).width / 2;
         const nTextX = ((nW - nTextW) / 2) + nX;
         const nTextY = ((nH - nTextH) / 2) + nY;

         ctx.fillText(sText, nTextX, nTextY);
         ctx.strokeStyle = cBorderColor;
         ctx.strokeRect(nX + 0.5, nY + 0.5, nW, nH);
      }

      const cellsLength = newInfo.setPosition.cells.length ?? Object.keys(newInfo.setPosition.cells).length;
      // Draw Cells
      for (let i = 0; i < cellsLength; i++) {
         const oCell = newInfo.setPosition.cells[i];
         const nX = oCell.position.x;
         const nY = oCell.position.y;
         const nW = oCell.size.width;
         const nH = oCell.size.height;

         // Drag Back
         let bDrawDrag = false;
         if (newInfo.setPosition.drags.includes(i)) {
            ctx.beginPath();
            ctx.fillStyle = `rgba(${newInfo.setPosition.commandBackColors[newInfo.setPosition.command]},1)`;
            ctx.fillRect(nX + 0.5, nY + 0.5, nW, nH);
            bDrawDrag = true;
         }

         // if (oCell.command !== undefined && oCell.command !== "") {
         if (oCell.command) {
            if (!bDrawDrag) {
               ctx.beginPath();
               ctx.fillStyle = `rgba(${newInfo.setPosition.commandBackColors[newInfo.setPosition.command]},0.5)`;
               ctx.fillRect(nX + 0.5, nY + 0.5, nW, nH);
            }

            const sText = `${oCell.sortNo}`;
            ctx.beginPath();
            ctx.font = newInfo.setPosition.textFont;
            ctx.fillStyle = newInfo.setPosition.textColor;
            ctx.textAlign = "left";
            ctx.textBaseline = "top";

            const nTextW = ctx.measureText(sText).width;
            const nTextH = ctx.measureText(m_sBaseText).width + ctx.measureText(m_sBaseText).width / 2;
            const nTextX = ((nW - nTextW) / 2) + nX;
            const nTextY = ((nH - nTextH) / 2) + nY;

            ctx.fillText(sText, nTextX, nTextY);
         }

         ctx.beginPath();
         ctx.strokeStyle = newInfo.setPosition.borderColor;
         ctx.strokeRect(nX + 0.5, nY + 0.5, nW, nH);
      }
   }

   function drawInsertSetPosition() {
      if (!_mergeDDPCells || !ctx) return;
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = "rgba(0,0,0,0.8)";
      ctx.fillRect(0, 0, width, height);
      const cellsLength = _mergeDDPCells.length ?? Object.keys(_mergeDDPCells.ddpCells).length;
      for (let i = 0; i < cellsLength; i++) {
         const oCell = _mergeDDPCells.ddpCells[i];
         const nX = oCell.position?.x || -1;
         const nY = oCell.position?.y || -1;
         const nW = oCell.size.width ?? -1;
         const nH = oCell.size.height ?? -1;

         if (oCell.command) {
            ctx.beginPath();
            const sText = (oCell.imgPos) ? oCell.imgPos : `${oCell.sortNo}`;
            ctx.fillStyle = `rgba(${oCell.commandBackColors},0.5)`;
            ctx.fillRect(nX + 0.5, nY + 0.5, nW, nH);

            ctx.font = info.setPosition?.textFont || "";
            ctx.fillStyle = info.setPosition?.foreColor || "";
            ctx.textAlign = "left";
            ctx.textBaseline = "top";

            const nTextW = ctx.measureText(sText).width;
            const nTextH = ctx.measureText(m_sBaseText).width + ctx.measureText(m_sBaseText).width / 2;
            const nTextX = ((nW - nTextW) / 2) + nX;
            const nTextY = ((nH - nTextH) / 2) + nY;

            ctx.fillText(sText, nTextX, nTextY);
         }

         // Border
         ctx.strokeStyle = "rgba(210, 210, 210, 0.7)";
         ctx.strokeRect(nX + 0.5, nY + 0.5, nW, nH);
      }
   }

   function drawBoard() {
      if (ctx) {
         ctx.clearRect(0, 0, width, height);
         ctx.fillStyle = "rgba(0,0,0,0.8)";
         ctx.fillRect(0, 0, width, height);

         if (info.editMode === EditMode.SELECT_CELL) drawSelectCell();
         else if (info.editMode === EditMode.SET_POSITION) drawSetPosition();
         else if (info.editMode === EditMode.SET_INSERT_POSITION) drawInsertSetPosition();
      }
   }

   function resizeCanvas() {
      if (!ddpCanvasRef.current) return;
      const canvas = ddpCanvasRef.current;

      // canvas1.width = window.innerWidth;
      // canvas1.height = window.innerHeight;
      //
      // Info.Width = window.innerWidth;
      // Info.Height = window.innerHeight;

      // canvas size로 설정
      canvas.width = 320;
      canvas.height = 200;

      // const info = this.Info;
      // info.Width = 320;
      // info.Height = 200;
      //
      // this.setSelectCellBounds();
      // this.setSetPositionCellBounds();
      // this.drawBoard();
   }

   function SetMouseMove_SelectCell(nX : number, nY : number, nBtn?: number) {
      let bRedraw = false;
      let bMatch = false;
      type SelectCell = Pick<Info, "selectCell">;
      const { selectCell }: SelectCell = CommonUtils.cloneObject(info);
      if (selectCell && info.selectCell) {
         const nSelIndex = info.selectCell.selectedIndex;
         if (nSelIndex > -1 && info.selectCell.cells.length > nSelIndex) {
            const nL = info.selectCell.cells[nSelIndex].position.x;
            const nT = info.selectCell.cells[nSelIndex].position.y;
            const nW = info.selectCell.cells[nSelIndex].size.width;
            const nH = info.selectCell.cells[nSelIndex].size.height;
            const nR = nL + nW;
            const nB = nT + nH;

            if (nX >= nL && nX <= nR && nY >= nT && nY <= nB) bMatch = true;
         }
         if (!bMatch) {
            selectCell.selectedIndex = -1;
            selectCell.selectedRow = -1;
            selectCell.selectedColumn = -1;

            for (let i = 0; i < info.selectCell.cells.length; i++) {
               const nL = info.selectCell.cells[i].position.x;
               const nT = info.selectCell.cells[i].position.y;
               const nW = info.selectCell.cells[i].size.width;
               const nH = info.selectCell.cells[i].size.height;
               const nR = nL + nW;
               const nB = nT + nH;

               if (nX >= nL && nX <= nR && nY >= nT && nY <= nB) {
                  selectCell.selectedIndex = i;
                  selectCell.selectedRow = info.selectCell.cells[i].row;
                  selectCell.selectedColumn = info.selectCell.cells[i].col;

                  const nSelRow = selectCell.selectedRow;
                  const nSelCol = selectCell.selectedColumn;

                  bMatch = true;
                  bRedraw = true;
                  for (let j = 0; j < info.selectCell.cells.length; j++) {
                     const nRow = info.selectCell.cells[j].row;
                     const nCol = info.selectCell.cells[j].col;
                     selectCell.cells[j].include = nCol <= nSelCol && nRow <= nSelRow;
                  }
               }
            }
         }
      }

      setInfo(prevState => ({
         ...prevState,
         selectCell,
      }));
   }

   function SetMouseDown_SelectCell(nX: number, nY: number, nBtn: number) {
      // @ts-ignore
      const newInfo: Info = CommonUtils.cloneObject(info);
      if (nBtn === 1) {
         SetMouseMove_SelectCell(nX, nY, nBtn);
         if (newInfo.selectCell && newInfo.selectCell.selectedRow > -1 && newInfo.selectCell.selectedColumn > -1) {
            newInfo.editMode = EditMode.SET_POSITION;
            if (newInfo.setPosition) {
               const rows = newInfo.selectCell.selectedRow + 1;
               const columns = newInfo.selectCell.selectedColumn + 1;
               newInfo.setPosition.rows = rows;
               newInfo.setPosition.columns = columns;

               set_dragCell((prevState: any) => ({
                  ...prevState,
                  rows,
                  columns,
               }));

               setSetPositionCellBounds();
            }

            setInfo(prevState => ({
               ...prevState,
               editMode: newInfo.editMode,
               setPosition: newInfo.setPosition,
            }));
         }
      }
   }

   function SetMouseUp_SetPosition() {
      // @ts-ignore
      const newInfo: Info = CommonUtils.cloneObject(info);
      if (!newInfo.setPosition) return;
      const nRow = newInfo.setPosition.rows - 1;
      const nCol = newInfo.setPosition.columns - 1;

      if (info.mouseDragCellIndex === undefined) {
         const arr: any = [];
         for (let i = 0; i < newInfo.setPosition.cells.length; i++) {
            const { row, col } = newInfo.setPosition.cells[i];

            if (row <= nRow && col <= nCol) newInfo.setPosition.command = "New";
            const nSeq = GetMaxSortNo(newInfo);
            newInfo.setPosition.cells[i].command = newInfo.setPosition.command;
            newInfo.setPosition.cells[i].sortNo = nSeq;

            arr.push(newInfo.setPosition.cells[i]);
         }
         set_dragCell((prevState: any) => ({
            ...prevState,
            dragSetPosition: arr,
         }));
         setSetPositionCheck("New");
      }
      newInfo.setPosition.drags = [];

      setInfo(prevState => ({
         ...prevState,
         setPosition: newInfo.setPosition,
      }));
   }

   function SetMouseDown_SetPosition(nX: number, nY: number, nBtn: number) {
      // @ts-ignore
      const newInfo: Info = CommonUtils.cloneObject(info);
      if (!newInfo.setPosition) return;
      if (nBtn === 1) {
         newInfo.setPosition.drags = [];
         let bMatch = false;

         for (let i = 0; i < newInfo.setPosition.buttons.length; i++) {
            const oBtn = newInfo.setPosition.buttons[i];
            const nL = newInfo.setPosition.buttons[i].position.x;
            const nT = newInfo.setPosition.buttons[i].position.y;
            const nW = newInfo.setPosition.buttons[i].size.width;
            const nH = newInfo.setPosition.buttons[i].size.height;
            const nR = nL + nW;
            const nB = nT + nH;
            if (nX >= nL && nX <= nR && nY >= nT && nY <= nB) {
               newInfo.mouseDragCellIndex = -1;
               if (oBtn.style === ButtonType.RADIO) {
                  setSetPositionCheck(oBtn.command);
                  // drawBoard() // redraw from the hook
               }
               else {
                  setSetPositionCheck("New");
                  setSetPositionClear();
               }

               bMatch = true;
               break;
            } // end of if
         } // end of for

         if (!bMatch) {
            // @ts-ignore
            for (let i = 0; i < newInfo.setPosition?.cells.length; i++) {
               const oCell = newInfo.setPosition?.cells[i];
               const nL = oCell.position.x;
               const nT = oCell.position.y;
               const nW = oCell.size.width;
               const nH = oCell.size.height;
               const nR = nL + nW;
               const nB = nT + nH;
               if (nX >= nL && nX <= nR && nY >= nT && nY <= nB) {
                  newInfo.setPosition?.drags.push(i);
                  newInfo.mouseDragCellIndex = i;
                  bMatch = true;
                  return;
               } // end of if
            } // end of for
         } // end of if

         setInfo(newInfo);
      } // end of if
   }

   function setSetPositionClear() {
      // @ts-ignore
      const newInfo: Info = CommonUtils.cloneObject(info);
      if (!newInfo.setPosition) return;
      for (let i = 0; i < newInfo.setPosition.cells.length; i++) {
         const cells = newInfo.setPosition.cells[i];
         cells.command = "";
         cells.sortNo = 0;
      }

      set_dragCell((prevState: any) => ({
         ...prevState,
         dragSetPosition: [],
         dragSetInsertPosition: [],
      }));

      setInfo(prevState => ({
         ...prevState,
         setPosition: newInfo.setPosition,
      }));
   }

   async function setDefaultDDPs(modality: string) {
      const defaultDDPs = await UserConfigAPI.getDefaultDDPsByModality(modality);
      if (!defaultDDPs) return null;

      let nIdx = 1;

      for (let i = 0; i < defaultDDPs.length; i++) {
         const defDDP = defaultDDPs[i];
         defDDP.ddp.idx = nIdx++;
         defDDP.ddp.modality = modality;
         defDDP.ddp.bodypart = "ALL";

         // await
         UserConfigAPI.createDDPSetting(defDDP.ddp);
      }

      const ddps = await UserConfigAPI.getUserDDPsByModality(modality) || [];

      for (let i = 0; i < ddps.length; i++) {
         const ddp = ddps[i];
         ddp.ddp.idx = i + 1;
         UserConfigAPI.updateUserDDPById(ddp.id, ddp.ddp);
      }

      return ddps;
   }

   function setDefaultMgProtocol() {
      if (_doubleCheck) clearDDP(false, true);
      setDefaultDDPs(selectedModality).then((result) => {
         ddpListRowData(result || []);
      });
   }

   function GetMaxSortNo(nInfo: Info) {
      const { setPosition } = nInfo;
      if (!setPosition) return;
      const obj: Array<any> = [];
      const asCommands = ["New"];
      for (let i = 0; i < oldCnt; i++) asCommands.push(`Old${i}`);
      asCommands.forEach((asCommand) => {
         // @ts-ignore
         obj[asCommand] = [];
      });

      const cellsLength = setPosition.cells.length ?? Object.keys(setPosition.cells).length;
      for (let i = 0; i < cellsLength; i++) {
         const oCell = setPosition.cells[i];
         // if (oCell.command) obj[oCell.command].push(`${oCell.sortNo.toString().padLeft(2, "0")},${i}`);
         const padString = CommonUtils.padLeft(oCell.sortNo.toString(), 2, "0");
         if (oCell.command) obj[oCell.command].push(`${padString},${i}`);
      }
      // setPosition.cells.forEach((oCell, index) => {
      //    if (oCell.command !== undefined && oCell.command !== "") {
      //       obj[oCell.command].push(`${oCell.sortNo.toString().padLeft(2, "0")},${index}`);
      //    }
      // });

      asCommands.forEach((asCommand) => {
         // @ts-ignore
         obj[asCommand].sort((v1: string, v2: string) => {
            const a = v1.replace(/^\d{2},/, "");
            const b = v2.replace(/^\d{2},/, "");
            return parseInt(a, 10) - parseInt(b, 10);
         });
      });

      asCommands.forEach((asCommand) => {
         // @ts-ignore
         obj[asCommand].forEach((rObj: any, index: number) => {
            const asCell = rObj.split(",");
            const nCellIndex = parseInt(asCell[1], 10);
            setPosition.cells[nCellIndex].sortNo = index + 1;
         });
      });

      setInfo(prevState => ({
         ...prevState,
         setPosition,
      }));
      // @ts-ignore
      return obj[setPosition.command].length;
   }

   function onMouseMove(e: any) {
      if (!ddpCanvas) return;

      const bound = ddpCanvas.getBoundingClientRect();
      const bw = 5;
      const nX = (e.clientX  - bound.left - bw) * (ddpCanvas.width / (bound.width - bw * 2));
      const nY = (e.clientY  - bound.top - bw) * (ddpCanvas.height / (bound.height - bw * 2));
      const nBtn = e.buttons;

      if (info.editMode === EditMode.SELECT_CELL) {
         SetMouseMove_SelectCell(nX, nY); // ,nBtn)
      }
      else if (info.editMode === EditMode.SET_POSITION) {
         // drag
         // this.SetMouseMove_SetPosition(nX, nY, nBtn);
      }
   }

   function onMouseDown(e: any) {
      if (!ddpCanvas) return;
      const bound = ddpCanvas.getBoundingClientRect();
      const bw = 5;
      const nX = (e.clientX  - bound.left - bw) * (ddpCanvas.width / (bound.width - bw * 2));
      const nY = (e.clientY  - bound.top - bw) * (ddpCanvas.height / (bound.height - bw * 2));
      const nBtn = e.buttons;

      if (info.editMode === EditMode.SELECT_CELL) SetMouseDown_SelectCell(nX, nY, nBtn);
      else if (info.editMode === EditMode.SET_POSITION) SetMouseDown_SetPosition(nX, nY, nBtn);
   }

   function onMouseUp(e: any) {
      if (!ddpCanvas) return;
      if (info.editMode === EditMode.SET_POSITION) SetMouseUp_SetPosition();
   }

   function settingLayout() {
      setInfo(prevState => ({
         ...prevState,
         mouseDragCellIndex: undefined,
      }));
      set_bInsertPosition(false);
      set_dragCell({
         dragSetPosition: [],
         dragSetInsertPosition: [],
      });
      if (saveBtnType === "modify") setSaveBtnType(SaveButtonType.UPDATE);
      startBody();
   }

   function startBody() {
      setG_Listchanged(false);
      initializeInfo();
      resizeCanvas();
      drawBoard();
   }

   function onRowSelected(e: RowSelectedEvent) {
      setG_row_selected(e.node);
   }

   function onRowClicked(e: RowClickedEvent) {
      setG_row_selected(e.node);
   }

   function onRowDragEnd(e: RowDragEndEvent) {
      const rowModality = e.nodes[0].data.modality;
      if (rowModality === "ALL") return;
      UserConfigAPI.getUserDDPsByModality(rowModality).then((ddps) => {
         if (ddps) {
            const newDdps = ddps;
            const { id } = e.node.data;
            const oldIdx = e.node.data.idx;
            // @ts-ignore
            const newIdx = e.node.rowIndex + 1;
            let cnt = Math.abs(oldIdx - newIdx);
            setG_drag(true);

            // idx sort
            for (let i = 0; i < newDdps.length; i++) {
               const ddp = newDdps[i];
               if (oldIdx < newIdx) { // old : 3, new : 5
                  if (id === ddp.id) ddp.ddp.idx = newIdx;
                  else if (id !== ddp.id) {
                     if (cnt === 0) break;
                     if (ddp.ddp.idx > oldIdx) {
                        ddp.ddp.idx -= 1;
                        cnt -= 1;
                     }
                  }
               }
               else if (oldIdx > newIdx) { // old : 3, new : 1
                  if (id === ddp.id) ddp.ddp.idx = newIdx;
                  if (id !== ddp.id) {
                     if (cnt === 0) break;
                     if (ddp.ddp.idx >= (oldIdx - cnt)) {
                        ddp.ddp.idx += 1;
                        cnt -= 1;
                     }
                  }
               }
               else break;
            }

            newDdps.sort((a, b) => (a.ddp.idx < b.ddp.idx ? -1 : 1));

            // localStorage : drag index update
            getLocalStorageHangingProtocol(newDdps, selectedModality, newIdx);

            // idx update
            newDdps.forEach((ddp)  => {
               UserConfigAPI.updateUserDDPById(ddp.id, ddp.ddp).then((res) => {
                  UserConfigAPI.getUserDDPsByModality(res.ddp.modality).then((lres) => {
                     if (lres) ddpListRowData(lres);
                  });
               });
            });
         }
      }).catch((reason) => {
         console.log(reason);
      });

      setSaveBtnType(SaveButtonType.CREATE);
      settingLayout();
   }

   // 더블클릭 이벤트 ( PASTE )
   function onRowDoubleClicked(e: RowDoubleClickedEvent) {
      const { btnType, isModifyWarningDialog } = e.context;
      if (btnType === SaveButtonType.UPDATE && isModifyWarningDialog) {
         setAlertOpen(true);
         return;
      }
      gridOptions.api?.setSuppressRowDrag(false);
      // set_bInsertPosition(false);
      mouseDoubleClicked(e.node.data);
   }

   function updateHangingProtocolExpand(expandValue:string) {
      fetch(`/api/user/option/expands/${expandValue}`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt")!,
            "Content-Type": "application/json",
         },
      }).then((response) => {
         if (!response.ok) {
            console.error("Message: ", response.status);
         }
      }).catch(err => console.error("Message : ", err));
   }

   return (
      <>
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.css"/>
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css"/>
         <link rel="stylesheet" href="/resource/style/ag-grid-hpacs.css"/>
         <StyledSettingContainer>
            {/* .expand-set-group */}
            <Collapse in={alertOpen}>
               <StyledAlert sx={{ width: 350 }} severity="warning">
                  <StyledAlertTitle>{i18n("label.warning")}</StyledAlertTitle>
                  <StyledAlertContents>
                     {i18n("msg.settingDialog.save.0")}
                     <br/>
                     {i18n("msg.settingDialog.save.1")}
                  </StyledAlertContents>

                  <StyledAlertButtonWrapper>
                     <OkBtn onClick={okAlter} text={"OK"}/>
                     <CancelBtn onClick={closeAlert} text={"Cancel"}/>
                     {/* <StyledKindButton kind={"ok"} onClick={okAlter}>OK</StyledKindButton> */}
                     {/* <StyledKindButton onClick={closeAlert}>Cancel</StyledKindButton> */}
                  </StyledAlertButtonWrapper>
               </StyledAlert>
            </Collapse>
            <StyledExpandSetGroup>
               <StyledExpandSettingWrapper>
                  <StyledExpandSetting>
                     <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                        <StyledInputLabel htmlFor="select-view-standard">{i18n("label.view")}</StyledInputLabel>
                        <StyledSelect
                           id="select-view-standard"
                           value={expandType}
                           onChange={handleExpandTypeChange}
                        >
                           <StyledMenuItem value="smartView">{i18n("label.smartView")}</StyledMenuItem>
                           <StyledMenuItem value="singleView">{i18n("label.singleView")}</StyledMenuItem>
                           <StyledMenuItem value="multiView">{i18n("label.multiView")}</StyledMenuItem>
                        </StyledSelect>
                     </FormControl>
                  </StyledExpandSetting>

                  <StyledInfoWrapper>
                     <StyledInfoIcon/>
                     <div className="view-info">
                        • {i18n("label.settingDialog.hangingProtocols.view.0")} <br/>
                        • {i18n("label.settingDialog.hangingProtocols.view.1")} <br/>
                        • {i18n("label.settingDialog.hangingProtocols.view.2")}
                     </div>
                  </StyledInfoWrapper>
               </StyledExpandSettingWrapper>
            </StyledExpandSetGroup>

            {/* ddp-set-group */}
            <StyledDdpSettingGroup>
               <StyledDdpSettingWrapper>
                  <StyledDdpSetting>
                     <FormControl variant="standard" sx={{ width: 110 }}>
                        <StyledInputLabel htmlFor="select-modality-standard">{i18n("label.modality")}</StyledInputLabel>
                        <StyledSelect
                           id="select-modality-standard"
                           value={selectedModality || "ALL"}
                           onChange={handleSelectedModalityChange}
                           disabled={modalityDisabled}
                        >
                           <StyledMenuItem value="ALL">ALL</StyledMenuItem>
                           {
                              modality && modality.map((item, index) => (
                                 <StyledMenuItem key={index} value={item}>{item}</StyledMenuItem>
                              ))
                           }
                        </StyledSelect>
                     </FormControl>
                  </StyledDdpSetting>

                  <StyledDdpSetting>
                     <FormControl variant="standard" sx={{ width: 110 }}>
                        <StyledInputLabel htmlFor="select-bodypart-standard">{i18n("label.bodypart")}</StyledInputLabel>
                        <StyledSelect
                           id="select-bodypart-standard"
                           value={selectedBodypart || "ALL"}
                           onChange={handleSelectedBodypartChange}
                        >
                           <StyledMenuItem value="ALL">ALL</StyledMenuItem>
                           {
                              bodypart && bodypart.map((item, index) => (
                                 <StyledMenuItem key={index} value={item}>{item}</StyledMenuItem>
                              ))
                           }
                        </StyledSelect>
                     </FormControl>
                  </StyledDdpSetting>

                  <StyledDdpSetting>
                     <FormControl variant="standard" sx={{ width: 300, minWidth: 300 }}>
                        <TextTypeField
                           label={i18n("label.studyDescription")}
                           shrink
                           variant="standard"
                           value={studyDesc || ""}
                           onChange={handleStudyDescChange}
                           // onChange={e => handleChange(e, "remoteTitle")}
                        />
                     </FormControl>
                  </StyledDdpSetting>
               </StyledDdpSettingWrapper>

               <StyledDdpSettingWrapper>
                  <StyledDdpSetting>
                     <StyledLabel>{i18n("label.defaultViewType")} : </StyledLabel>
                     <FormControl>
                        {/* <FormLabel id="radio-buttons-group-label">{i18n("label.defaultViewType")}</FormLabel> */}
                        <RadioGroup
                           aria-labelledby="radio-buttons-group-label"
                           name="radio-buttons-group"
                           value={viewType}
                           onChange={handleViewTypeChange}
                           row
                        >
                           <FormControlLabel value="Tile" control={<Radio/>} label={i18n("label.tile")}/>
                           <FormControlLabel value="Stack" control={<Radio/>} label={i18n("label.stack")}/>
                        </RadioGroup>
                     </FormControl>
                  </StyledDdpSetting>
               </StyledDdpSettingWrapper>

               <StyledDdpSettingWrapper>
                  <StyledLayoutSetting>
                     <canvas
                        ref={ddpCanvasRef}
                        width={width}
                        height={height}
                        onMouseMove={onMouseMove}
                        onMouseDown={onMouseDown}
                        onMouseUp={onMouseUp}
                     />
                     <StyledSettingIcon onClick={settingLayout} />
                  </StyledLayoutSetting>
               </StyledDdpSettingWrapper>
            </StyledDdpSettingGroup>

            {/* ddp-btn-group */}
            <StyledButtonGroup>
               <StyledContextText>
                  {
                     !isSameModality
                        ? <FormControlLabel control={<Checkbox value={isSameModality} onChange={handleSameModalityCheckedChange}/>}
                           label={i18n("label.settingDialog.hangingProtocols.sameModality")}/>
                        : null
                  }
                  {
                     !isMgProtocol
                        ? <StyledMgButton
                           onClick={setDefaultMgProtocol}
                        >{i18n("button.addDefaultMGProtocol")}</StyledMgButton>
                        : null
                  }
               </StyledContextText>
               <StyledContextButtonWrapper>
                  <OkBtn sx={{ width: 110 }} onClick={setHangingProtocol} text={saveBtnType}/>
                  <CancelBtn sx={{ width: 110 }} onClick={() => clearDDP(true, true)} text={"New"} />
                  {/* <StyledKindButton kind={"ok"} width={110} onClick={setHangingProtocol}>{saveBtnType}</StyledKindButton> */}
                  {/* <StyledKindButton width={110} onClick={() => clearDDP(true, true)}>New</StyledKindButton> */}
               </StyledContextButtonWrapper>
            </StyledButtonGroup>

            {/* ddp-grid-group */}
            {/* AgGridReact */}
            <StyledGridGroup className={"ag-theme-balham-dark setting-ag-grid"}>
               <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  gridOptions={gridOptions}
                  // rowDragManaged={true}
                  // animateRows={true}
                  context={{ btnType: saveBtnType, isModifyWarningDialog: modifyWarningDialog }}
                  getContextMenuItems={getContextMenuItems}
                  onRowSelected={onRowSelected}
                  onRowClicked={onRowClicked}
                  onRowDragEnd={onRowDragEnd}
                  onRowDoubleClicked={onRowDoubleClicked}
               />
            </StyledGridGroup>
         </StyledSettingContainer>
      </>
   );
}
