/* eslint-disable prefer-destructuring,no-restricted-syntax,no-param-reassign,no-unused-vars */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/iron-autogrow-textarea/iron-autogrow-textarea";
import "@vaadin/vaadin-context-menu/vaadin-context-menu";
import "@polymer/iron-selector/iron-selector";
import "@polymer/paper-checkbox/paper-checkbox";
import "@polymer/paper-item/paper-item";
import "@polymer/paper-icon-button/paper-icon-button";
import "@vaadin/vaadin-icons/vaadin-icons";
import "@polymer/paper-tabs";
import "@polymer/iron-icon";
import "@polymer/iron-pages";
import "@polymer/paper-tooltip/paper-tooltip";
import moment from "moment-timezone";
import CommonUtils from "../../public/resource/js/utils/common";
import mixinCommons from "../common-mixin";
import store from "../redux/store";
import { RelatedWorklistActionType } from "../redux/reducers/related-worklist.ts";
import {CommonActionType, CustomContextMenuType} from "../redux/reducers/common";
import {ReportActionType} from "../redux/reducers/report";

class HHReportOldfilm extends mixinCommons(PolymerElement) {
   static get is() {
      return "hh-report-oldfilm";
   }

   static get template() {
      return html`
         <style>
            :host {
               display: block;
               height: 100%;
               width: 100%;
            }

            .container {
               position: relative;
               display: flex;
               flex-direction: row;
               width: 100%;
            }

            paper-tabs {
               --paper-tabs-selection-bar: {
                  border-bottom: 3px solid #0087cb;
               };

               --paper-tabs-content: {
                  font-family: NotoSansCJKMedium, NotoSansCJK, NotoSansCJKLight !important;
                  color: #aaaaaa;
                  /*background-color: #252934;*/
                  font-size: 11px;
                  font-weight: bold;
               };
               height: 40px;
            }

            paper-tabs paper-tab.iron-selected {
               color: #0087cb;
            }

            iron-pages {
               height: calc(100% - 40px);
               width: 100%;
               overflow-y: auto;
               overflow-x: hidden;
               background-color: #212731;
            }

            .div-sort {
               width: 60px;
            }

            .div-tabs {
               width: calc(100% - 60px);
            }

            .contents-container {
               height: 100%;
               width: 100%;
            }

            .contents-title {
               height: auto;
               min-height: 20px;
               display: flex;
               align-items: center;
               background-color: #2d333f;
               font-weight: bold;
               font-size: 12px;
               box-shadow: 0px 3px 9px -2px #000000;
               color: #aaaaaa;
               width: calc(100% - 10px);
               line-height: 1.5;
               padding: 5px;
            }

            .contents-body {
               width: 100%;
               background-color: rgba(0, 0, 0, 0.13);
               display: flex;
               justify-content: center;
            }

            iron-autogrow-textarea {
               padding: 0px;
               width: calc(100% - 20px);
               color: #aaa;
               border: 0px;
               resize: none;
               outline: none !important;
               font-size: 14px;
               line-height: 1.5em;
               --iron-autogrow-textarea: {
                  padding: 0px;
                  font-family: NotoSansCJK, NotoSansCJKLight;
               }
            }

            input {
               width: 100%;
               font-size: 13px;
               background-color: #2d333f;
               color: #aaa;
            }

            .class-oldfilm-title {
               width: 100%;
               font-size: 13px;
               color: #aaa;
               border: 0px;
            }

            .class-oldFilmReportSelected{
               height: 40px;
               width: 100%;
               font-size: 11px;
               font-weight: bold;
               color: #aaaaaa;
               display: flex;
               justify-content: center;
            }

            .checkBox-style{
               font-size: 13px;
               color: #aaaaaa;
               --paper-checkbox-checked-color: #0087cb;
               --paper-checkbox-label-color: #aaaaaa;
               --paper-checkbox-unchecked-color: #4c5667;
               padding: 3px;
            }

            .iconSortBtn{
               margin: 0px 1px 0px 1px;
               font-size: 13px;
               color: #aaaaaa;
               padding-right: 0px;
               width: 20px;
            }

            paper-icon-button {
               width: 25px;
               height: 100%;
               padding: 5px;
               color: #aaa;
            }

            /* context menu */
            .context-menu {
               display: none;
               position: absolute;
               z-index: 5;
               padding: 1px;
               width: 190px;
               border-radius: 2px;
               box-shadow: none;
            }

            .context-menu--active {
               display: block;
            }

            .context-menu__items {
               list-style: none;
               margin: 0;
               padding: 0;
               font-size: 15px;
            }

            .context-menu__item {
               display: block;
               margin-bottom: 4px;
            }

            .context-menu__item:last-child {
               margin-bottom: 0;
            }

            .context-menu__link {
               display: block;
               padding: 4px 12px;
               color: #aaaaaa;
               text-decoration: none;
            }

            .context-menu__link:hover {
               color: #0087cb;
               background-color: #3d4749;
            }

            ::-webkit-scrollbar {
               width: 15px;
               height: 15px;
            }

            ::-webkit-scrollbar-thumb {
               background-color: #6b6b6b;
               border-radius: 10px;
               background-clip: content-box;
               border: 3px solid rgba(255,255,255,0);
            }

            .contents-area {

            }

            .ag-theme-balham-dark .ag-popup-child:not(.ag-tooltip-custom) {
               box-shadow: 5px 5px 10px rgba(0,0,0,.3);
            }

            .ag-theme-balham-dark .ag-menu {
               border: 1px solid;
               border-color: #424242;
               background: #2d3436;
               border-radius: 2px;
               box-shadow: none;
               padding: 4px;
            }

            iron-icon, div[suffix] {
               color: #f5f5f5;
               margin: 5px 8px 5px 0px;
               width: 14px;
               height: 14px;
               cursor: pointer;
            }

            .ag-theme-balham-dark {
               -webkit-font-smoothing: antialiased;
               color: #f5f5f5;
               font-size: 12px;
               line-height: normal;
            }

            .hideContextMenu {
               display: none;
            }

            .ag-theme-balham-dark .ag-menu-option-disabled {
               opacity: .5;
               cursor: default;
            }

            .ag-menu-option:not(.ag-menu-option-disabled):hover {
               background: #3d4749;
            }

            .ag-menu-option {
               cursor: pointer;
            }

            #contextMenu {
               position: absolute;
               z-index: 5;
            }

            #fontDsc {
               display: inline-block;
               overflow: hidden;
            }

            .textEllipse {
               white-space: nowrap;
               text-overflow: ellipsis;
               overflow: hidden;
            }
         </style>


         <div class="container">
            <div class="div-tabs">
               <paper-tabs id="oldFilmReportTabs" selected="{{selected}}" noink>
                  <paper-tab>{{t("label.all")}}</paper-tab>
                  <paper-tab>{{t("label.modal")}}</paper-tab>
                  <paper-tab>{{t("label.body")}}</paper-tab>
                  <paper-tab>{{t("label.modalBody")}}</paper-tab>
                  <paper-tab>{{t("label.studyDesc")}}</paper-tab>
               </paper-tabs>
            </div>
            <div class="div-sort">
               <div class="class-oldFilmReportSelected" id="oldFilmSensitive">
                  <div id="fontDsc" class="textEllipse" on-click="dscSort">
                     <paper-icon-button id="iconDescending" noink icon="[[icon]]" class="iconSortBtn" ></paper-icon-button>
                     <span>[[direction]]</span>
                  </div>
               </div>
            </div>
         </div>

         <iron-pages selected="0">
            <div id="oldFilmContainer" class="contents-container context-menus">

               <template is="dom-repeat" items="{{oldfilm}}">
                  <div id="{{item.id}}" index="[[index]]">
                     <div class="contents-title" id="oldReportTitle">
                        <input class="class-oldfilm-title" id="oldfilmId" value="{{item.id}}" style="display: none;"/>
                        <!--                        <input class="class-oldfilm-title" id="oldfilmTitle" value="{{item.title}}" style$="color: {{item.color}}" readonly/>-->
                        <span class="class-oldfilm-title" style="color: {{item.color}}">{{item.title}}</span>
                        <paper-icon-button disabled icon="" style="color: red; width: 10px; height: 12px; padding: 0"></paper-icon-button>
                        <div style="display:inline-block padding-left: 20px">
                           <iron-icon icon="vaadin:copy-o" style="margin: 5px 8px 5px 8px" on-click="handleCopyAndPaste" data-index$="[[index]]" ></iron-icon>
                           <paper-tooltip>{{t("label.copyPasteReport")}}</paper-tooltip>
                        </div>
                     </div>
                     <div class="contents-body"">
                     <iron-autogrow-textarea class="class-old-report-textarea" id="oldfilmReport" value="{{item.report}}" readonly on-contextmenu="setElemEditor"></iron-autogrow-textarea>
                  </div>
            </div>
            </template>
            </div>
         </iron-pages>


         <div id="contextMenu" class="contextMenu hideContextMenu">
            <div class="ag-theme-balham-dark ag-popup">
               <div class="ag-menu ag-ltr ag-popup-child ag-keyboard-focus" style="min-width: 125px;">
                  <div class="ag-menu-list" tabindex="-1">
                     <div class="ag-menu-option do-copy" tabindex="-1" on-click="handleCopy">
                        <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:content-copy"></iron-icon></span>
                        <span class="ag-menu-option-text ag-menu-option-part">{{t("button.contextMenu.copy")}}</span>
                        <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                        <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                     </div>
                     <div class="ag-menu-option do-select-all" tabindex="-1" on-click="handleSelectAll">
                        <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:select-all"></iron-icon></span>
                        <span class="ag-menu-option-text ag-menu-option-part">{{t("button.contextMenu.selectAll")}}</span>
                        <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                        <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      `;
   }

   static get properties() {
      return {
         selectedRelatedRow: {
            type: Object,
            observer: "onSelectRelatedRow"
         },
         icon: {
            type: String,
            value: "vaadin:chevron-down-small"
         },
         direction: {
            type: String,
            value: ""
         },
         oldfilm: {
            type: Array,
            value: []
         },
         g_reportList: {
            type: Array,
            value: []
         },
         g_SelectedRow: { // new row
            type: Object,
            value: {},
            observer: "onSelectRow"
         },
         g_RelatedRow: { // rel row
            type: Object,
            value: {}
         },
         g_SelectedCaseDate: {
            type: Object,
            value: {}
         },
         _selectedRelatedReport: {
            type: Object,
            value: {}
         },
         sameModality: {
            type: Array,
            value: []
         },
         sameBodypart: {
            type: Array,
            value: []
         },
         sameModalityAndBodypart: {
            type: Array,
            value: []
         },
         sameStudyDesc: {
            type: Array,
            value: []
         },
         contextMenuActive: {
            type: String,
            value: ""
         },
         taskItemClassName: {
            type: String,
            value: ""
         },
         taskItemInContext: {
            type: Object,
            value: {}
         },
         clickCoords: {
            type: Number,
            value: 0
         },
         selectedOldFilm: {
            type: Number,
            value: -1
         },
         clickCoordsX: {
            type: Number,
            value: 0
         },
         clickCoordsY: {
            type: Number,
            value: 0
         },
         menu: {
            type: Object,
            value: {}
         },
         menuState: {
            type: Number,
            value: 0
         },
         menuWidth: {
            type: Number,
            value: 0
         },
         menuHeight: {
            type: Number,
            value: 0
         },
         windowWidth: {
            type: Array
         },
         windowHeight: {
            type: Number,
            value: 0
         },
         selected: {
            type: Number,
            value: 0,
            observer: "_selectedTabChanged"
         },
         elemEditor: {
            type: Object
         },
         customContextMenuState: {
            type: Number,
            observer: "changeCustomContextMenuState"
         },
         customUniqueKey: {
            type: String,
            readonly: true,
            computed: "computeCustomUniqueKey()"
         }
      };
   }

   computeCustomUniqueKey() {
      return `${CustomContextMenuType.REPORT_RELATED}_${Math.random()}`;
   }

   ready() {
      super.ready();

      this.init();
      this.initContextMenu();

      store.subscribe(() => {
         this.selected = store.getState().relatedWorklist.activeTabCode;
         const e = store.getState().worklist.row;
         this.g_SelectedRow = e?.detail;
         this.selectedRelatedRow = store.getState().relatedWorklist.row;
         this.customContextMenuState = store.getState().common.customContextMenu;
      });

      this.$.oldFilmReportTabs.addEventListener("selected-changed", (e) => {

         this.getUserStyle().then((result) => {
            if (result.id) {
               result.tabIndex.worklistRelated = e.detail.value;
               this.updateWorkListTabIndex(result.id, result.tabIndex);
            }
         }).catch((err) => {
            console.info(err);
         });
      });

      this.$.oldFilmContainer.addEventListener("dom-change", (e) => {
         if(this.g_RelatedRow.id) this.scrollEvent(this.g_RelatedRow);
      });

      this.shadowRoot.querySelectorAll(".context-menus").forEach(el => this.setContextMenu(el));
      document.addEventListener("click", () => {
         // if(!this.$.contextMenu.classList.contains("hideContextMenu"))
         //    this.$.contextMenu.classList.add("hideContextMenu");
         this.elemEditor = undefined;
         // console.log("[report-related] document click", (this.customContextMenuState));
         if (this.customContextMenuState !== undefined) store.dispatch({ type: CommonActionType.HIDE_CONTEXT_MENU });
      });

      const links = [ /* To adjust the width of FLeader's POPUP Menu */
         {id: "$related_copy_btn", value:  this.$.oldFilmContainer }
      ]; window.__element_refers__ = window.__element_refers__ ? [...window.__element_refers__, ...links] : links;

      window.addEventListener("message", (event) => {
         switch((event.data||{}).event) {
         case "handleCopyAndPasteReportWithKey": {
            if (this.selectedOldFilm === -1) return;
            // report로 opinion값 전달
            store.dispatch({ type: ReportActionType.SET_COPY_AND_PASTE_REPORT, payload: { detail: this.oldfilm[this.selectedOldFilm] } });
            break;
         }
         case "FILMBOX_CLOSED": {
            // #18074 [HPACS > 김성현원장님] Related 영상 list에 표시하는 부분 보완
            // filmbox가 닫혀 있을 때는 Viewing 아이콘을 실시간으로 빼준다
            const {oldFilmContainer} = this.$;
            if(oldFilmContainer.children) {
               for (let i = 0; i < oldFilmContainer.children.length; i++) {
                  if(oldFilmContainer.children[i].querySelector("paper-icon-button")) oldFilmContainer.children[i].querySelector("paper-icon-button").icon = "";
               }
            }
            break;
         }
         default:
         }
      });

      window.addEventListener("relatedDblClickEvent", (e) => {
         // #18074 [HPACS > 김성현원장님] Related 영상 list에 표시하는 부분 보완
         // related에서 필름박스를 열었을때 눈모양으로 표시한다
         const relIds = e.detail;
         this.initRelatedListStyle();
         this.relatedCurrentViewing(relIds);
      });
   }

   setElemEditor(e) {
      this.elemEditor = e.model.children[1].querySelector("#oldfilmReport");
   }

   setContextMenu(el) {
      el.addEventListener("contextmenu", e =>  this.showContextMenu(e, el) );
   }

   showContextMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      const menus = this.$.contextMenu;
      const rect = this.getBoundingClientRect();
      const {x, y} = rect;
      const {pageX, pageY} = e;

      const l = pageX - x;
      const t = pageY - y;

      const left = `${l}px`;
      const top  = `${t}px`;

      menus.style.left = left;
      menus.style.top = top;

      if(menus.classList.contains("hideContextMenu") && this.elemEditor) {
         menus.classList.remove("hideContextMenu"); // SHOW
         store.dispatch({ type: CommonActionType.SHOW_CONTEXT_MENU, payload: this.customUniqueKey });
      }

      this.disableContextMenuItem(this.elemEditor);
   }

   disableContextMenuItem(target) {
      if(!target) return;

      const menus = this.$.contextMenu;

      const start = target.selectionStart;
      const end   = target.selectionEnd;

      if(start === end) {
         this.addDisableContextMenuItem(menus.querySelector(".do-copy"));
      } else {
         this.removeDisableContextMenuItem(menus.querySelector(".do-copy"));
      }

   }

   addDisableContextMenuItem(el) {
      if(!el) return;

      if(!el.classList.contains("ag-menu-option-disabled"))
         el.classList.add("ag-menu-option-disabled");
   }

   removeDisableContextMenuItem(el) {
      if(!el) return;

      if(el.classList.contains("ag-menu-option-disabled"))
         el.classList.remove("ag-menu-option-disabled");
   }

   handleCopyAndPaste(event) {
      const { index } =  event.target.dataset;
      // report로 opinion값 전달
      store.dispatch({ type: ReportActionType.SET_COPY_AND_PASTE_REPORT, payload: { detail: this.oldfilm[index] } });
   }


   handleCopy() {
      if(!this.elemEditor || (this.$.contextMenu.querySelector(".do-copy").classList.contains("ag-menu-option-disabled"))) return;
      const v = this.elemEditor.textarea.value;
      const start = this.elemEditor.selectionStart;
      const end   = this.elemEditor.selectionEnd;

      navigator.clipboard.writeText(v.substring(start, end)).then(() => {
         const params = {
            isErr: false,
            msg: this.t("msg.opinion.copy.text")
         };

         this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: params}));
      });
   }

   handleSelectAll() {
      const ta = this.elemEditor;

      ta.textarea.select();
      ta.textarea.focus();
   }

   _selectedTabChanged(newValue, oldValue) {
      // default값 0으로 update하는 이슈 발생
      if(CommonUtils.isNumeric(oldValue)) {
         store.dispatch({type: RelatedWorklistActionType.SET_ACTIVE_TAB, payload: newValue});

         this.setReportData();
         this.g_RelatedRow = {};
      }
      // const detail = this.selected;
      // store.dispatch({ type: RelatedWorklistActionType.SET_ACTIVE_TAB, payload: detail });
   }

   setReportData() {

      // #16202 this.oldfilm (Related Report List) 초기화
      this.init();

      switch (this.selected) {
      case 0:
         this.oldfilm = this.g_reportList;
         break;
      case 1:
         this.oldfilm = this.convertOpinion(this.sameModality);
         break;
      case 2:
         this.oldfilm = this.convertOpinion(this.sameBodypart);
         break;
      case 3:
         this.oldfilm = this.convertOpinion(this.sameModalityAndBodypart);
         break;
      case 4:
         this.oldfilm = this.convertOpinion(this.sameStudyDesc);
         break;
      default:
         this.oldfilm = this.g_reportList;
      }
   }


   convertOpinion(report) {
      const oldfilmList = [];
      for (const row of report) {
         const approveDtime = row.opinionApprovedDtime;
         // #16467 분당제생병원에서 판독했을시 RS:3A지만 판독문이 들어있지 않기 때문에 null 체크 추가
         if(row.opinion !== null) {
            for (const opinion of row.opinion) {
               if (opinion.approveTime === approveDtime) {
                  oldfilmList.push(this.setOldfilmOpinion(row, opinion));
               }
            }
         }
      }
      return oldfilmList;
   }

   setOldFilmList(previousReports, id){
      if(previousReports.length > 0) {
         if (previousReports.length > 1) {
            this.clearOldReporListCheckbox();
         }
         const oldfilmList = [];

         if (previousReports.length > 1) {
            for (let i = 0; i < previousReports.length; i++) {
               if (previousReports[i].id === id) {
                  this.g_SelectedCaseDate = previousReports[i];
                  break;
               }
            }
         }

         for (let i = 0; i < previousReports.length; i++) {

            if (previousReports[i].id !== id) {

               const previousReport = previousReports[i];

               if (this.isNotBlank(previousReport.modality[0]) && (previousReport.modality[0] === this.g_SelectedRow.modality) && (previousReport.id !== id)) {
                  this.sameModality.push(previousReport);
               }

               if (this.isNotBlank(previousReport.bodypart[0]) && (previousReport.bodypart[0] === this.g_SelectedRow.bodypart) && (previousReport.id !== id)) {
                  this.sameBodypart.push(previousReport);
               }

               if (this.isNotBlank(previousReport.modality[0]) && (previousReport.modality[0] === this.g_SelectedRow.modality) && this.isNotBlank(previousReport.bodypart[0]) && (previousReport.bodypart[0] === this.g_SelectedRow.bodypart) && (previousReport.id !== id)) {
                  this.sameModalityAndBodypart.push(previousReport);
               }

               if (this.isNotBlank(previousReport.studyDescription[0]) && (previousReport.studyDescription[0] === this.g_SelectedRow.studyDescription) && (previousReport.id !== id)) {
                  this.sameStudyDesc.push(previousReport);
               }

               const approveDtime = previousReport.opinionApprovedDtime;

               // #16467 분당제생병원에서 판독했을시 RS:3A지만 판독문이 들어있지 않기 때문에 null 체크 추가
               if(previousReport.opinion !== null) {
                  for (const opinion of previousReport.opinion) {
                     if (opinion.approveTime === approveDtime) {
                        oldfilmList.push(this.setOldfilmOpinion(previousReport, opinion));
                     }
                  }
               }
            }
         }// for
         this.g_reportList = oldfilmList;
         // this.oldfilm = oldfilmList;
         this.setReportData();
      } else {
         // this.hiddenOldFilmSensitive();
         this.oldfilm = [];
      }
   }

   isNotBlank(value) {
      if(value === null) return false;
      if (typeof value === "undefined") return false;
      if (typeof value === "string" && value === "") return false;
      if (typeof value === "string" && value === "-") return false;
      return true;
   }

   seletedPreviousReports(row) {
      // this.hiddenOldFilmSensitive();
      const oldfilmList = [];
      for (const previousReports of this.g_reportList) {
         if(previousReports.id === row[0].id) {
            oldfilmList.push(previousReports);
            break;
         }
      }
      this.oldfilm = oldfilmList;
   }

   clearOldReporListCheckbox(){
      this.oldfilm = this.g_reportList;
   }

   // hiddenOldFilmSensitive(){
   //    this.$.oldFilmSensitive.style.display = "none";
   // }

   init(){
      // row 이동시 초기화가 안되는 문제가 있어서 공통으로 뺌
      this.direction = this.t("label.sort.desc");
      this.icon = "vaadin:chevron-down-small";
      this.oldfilm = [];
   }

   setOldfilmOpinion(reports, opinion){
      const resultOpinion = {};
      if(opinion.finding){
         resultOpinion.finding = opinion.finding;
      }else{
         resultOpinion.finding = "";
      }
      if(opinion.conclusion){
         resultOpinion.conclusion = opinion.conclusion;
      }else{
         resultOpinion.conclusion = "";
      }
      if(opinion.recommendation){
         resultOpinion.recommendation = opinion.recommendation;
      }else{
         resultOpinion.recommendation = "";
      }
      if (reports.addendum) {
         resultOpinion.addendum = reports.addendum;
      } else {
         resultOpinion.addendum = "";
      }

      let ellapsedTime = "";
      if(this.g_SelectedCaseDate){

         const date = new Date(parseInt(this.g_SelectedCaseDate.opinionApprovedDtime, 10));
         // console.log(date.getFullYear()+" "+(date.getMonth()+1)+" "+date.getDate());

         const date2 = new Date(parseInt(reports.opinionApprovedDtime, 10));
         // console.log(date2.getFullYear()+" "+(date2.getMonth()+1)+" "+date2.getDate());

         const diff = date2 - date;
         const cDay = 24 * 60 * 60 * 1000;
         ellapsedTime = parseInt(diff/cDay, 10);
         // console.log("일수 차 :"+ ellapsedTime);
      }

      const patientId = CommonUtils.isEmptyValue(reports.patientID) ? "N/A" : reports.patientID;
      const patientName = CommonUtils.isEmptyValue(reports.patientName) ? "N/A" : reports.patientName;
      const studyDescription = (reports.studyDescription && reports.studyDescription[0]) ? reports.studyDescription[0] : "N/A";
      const studyDate = (reports.studyDate && reports.studyDate[0]) ? this.convertDateFormat(reports.studyDate[0]) : "N/A";
      const studyTime = (reports.studyTime && reports.studyTime[0]) ? this.convertTimeFormat(reports.studyTime[0]) : "N/A";

      const title = `${patientId}, ${patientName}, ${studyDescription}, ${studyDate} ${studyTime}`;
      resultOpinion.color = "white";

      const referralApproveDtime = new Date(parseInt(reports.opinionApprovedDtime, 10));
      const opinionData = `Approved by ${opinion.approver} at ${ this.convertReferralApproveDTime(referralApproveDtime)}\n\r`;

      // let studyDesc = "";
      // if(reports.studyDescription) studyDesc = reports.studyDescription[0];

      let finding = ``;
      if (resultOpinion.finding)  finding = `< Findings >\n${this.convertContentTab(resultOpinion.finding)}\n\r`;

      let conclusion = ``;
      if (resultOpinion.conclusion) conclusion = `< Conclusion >\n${this.convertContentTab(resultOpinion.conclusion)}\n\r`;

      let recommendation = ``;
      if (resultOpinion.recommendation) recommendation = `< Recommendation >\n${this.convertContentTab(resultOpinion.recommendation)}\n\r`;

      let addendum = ``;
      if (resultOpinion.addendum && resultOpinion.addendum.length > 0) {
         addendum += `\n\r< Addendum >\n`;
         resultOpinion.addendum.forEach((item) => {
            addendum += `[ ${item.approver} at ${this.convertDateTimeFormat(item.approveTime)}]\n`;
            addendum += `${item.conclusion}\n`;
         });
      }

      // const report = `
      //    ${finding}${conclusion}${recommendation}
      // `;

      resultOpinion.report = `${finding}${conclusion}${recommendation}${opinionData}${addendum}`;

      resultOpinion.id = reports.id;
      resultOpinion.title = title;
      // resultOpinion.report = `
      //    ${report}\r\n
      //    ${opinionData}
      // `;

      return resultOpinion;
   }

   convertContentTab(content) {
      return content.replace(/\t/g, "    ");
   }

   convertReferralApproveDTime(approveDtime){
      let aMonth = approveDtime.getMonth() + 1;
      if(parseInt(aMonth, 10) < 10) aMonth = `0${aMonth}`;
      let aDay = approveDtime.getDate();
      if(parseInt(aDay, 10) < 10) aDay = `0${aDay}`;
      let aHour = approveDtime.getHours();
      if(parseInt(aHour, 10) < 10) aHour = `0${aHour}`;
      let aMinutes = approveDtime.getMinutes();
      if(parseInt(aMinutes, 10) < 10) aMinutes = `0${aMinutes}`;
      return `${approveDtime.getFullYear() }-${ aMonth}-${ aDay} ${aHour}:${aMinutes}`;
   }

   convertTimeFormat(time) {
      if(!time) return "00:00:00";

      const hour = time.substring(0,2);
      const min = time.substring(2,4);
      const sec = time.substring(4,6);
      // eslint-disable-next-line no-param-reassign
      time = `${hour}:${min}:${sec}`;

      return time;
   }

   convertDateFormat(date) {
      if(!date) return "0000-00-00";

      const year = date.substring(0,4);
      const month = date.substring(4,6);
      const day = date.substring(6,8);
      // eslint-disable-next-line no-param-reassign
      date = `${year}-${month}-${day}`;

      return date;
   }

   convertDateTimeFormat(datetime) {
      if (!datetime) return "0000-00-00 00:00:00";

      return moment(new Date(parseInt(datetime, 10))).format("YYYY-MM-DD HH:mm:ss");
   }

   ascSort(){
      this.$.iconAscending.hidden = true;
      this.$.fontAsc.hidden = true;
      this.$.iconDescending.hidden = false;
      this.$.fontDsc.hidden = false;
      this.sort();
   }

   dscSort(){
      if(this.oldfilm != null && this.oldfilm.length > 0) {
         if(this.direction === this.t("label.sort.desc")) {
            this.direction = this.t("label.sort.asc");
            this.icon = "vaadin:chevron-up-small";
         } else {
            this.direction = this.t("label.sort.desc");
            this.icon = "vaadin:chevron-down-small";
         }
         this.sort();
      }
   }

   sort(){
      const oldfilmList = [];
      for(let i= this.oldfilm.length -1; i>=0; i--){
         let oldfilm = {};
         oldfilm = this.oldfilm[i];
         oldfilmList.push(oldfilm);
         if(i === 0){
            this.oldfilm = oldfilmList;
         }
      }
   }

   /* CONTEXT SCRIPT */
   // ////////////////////////////////////////////////////////////////////////////
   // ////////////////////////////////////////////////////////////////////////////
   //
   // H E L P E R    F U N C T I O N S
   //
   // ////////////////////////////////////////////////////////////////////////////
   // ////////////////////////////////////////////////////////////////////////////

   /**
    * Function to check if we clicked inside an element with a particular class
    * name.
    *
    * @param {Object} e The event
    * @param {String} className The class name to check against
    * @return {Boolean}
    */
   clickInsideElement( e, className ) {
      if (e.path[3].nodeName.includes(className)) {
         this._selectedRelatedReport = e.path[3].__dataHost.__data.item;
         return true;
      }
      return false;
   }

   /**
    * Get's exact position of event.
    *
    * @param {Object} e The event passed in
    * @return {Object} Returns the x and y position
    */
   getPosition(e) {
      let posx = 0;
      let posy = 0;

      // eslint-disable-next-line no-param-reassign
      if (!e) e = window.event;
      if (e.pageX || e.pageY) {
         posx = e.pageX;
         posy = e.pageY;
      } else if (e.clientX || e.clientY) {
         posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
         posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      }

      return {
         x: posx - 10,
         y: posy - 20
      };
   }

   // ////////////////////////////////////////////////////////////////////////////
   // ////////////////////////////////////////////////////////////////////////////
   //
   // C O R E    F U N C T I O N S
   //
   // ////////////////////////////////////////////////////////////////////////////
   // ////////////////////////////////////////////////////////////////////////////

   /**
    * Initialise our application's code.
    */
   initContextMenu() {
      this.menu = this.$.contextMenu;
      this.contextListener();
      this.clickListener();
      this.keyupListener();
      this.resizeListener();
   }

   /**
    * Listens for Right click events.
    */
   contextListener() {
      this.addEventListener( "contextmenu", (e) => {
         if(this.g_SelectedRow.readingStatus !== "3A"){
            this.taskItemInContext = this.clickInsideElement( e, this.taskItemClassName );
            if ( this.taskItemInContext ) {
               e.preventDefault();
               this.toggleMenuOn();
               this.positionMenu(e);
            } else {
               this.taskItemInContext = null;
               this.toggleMenuOff();
            }
         }
      });
   }

   /**
    * Listens for Left click events.
    */
   clickListener() {
      window.addEventListener( "click", () => {
         if(this._selectedRelatedReport !== null){
            this.toggleMenuOff();
         }
      });
   }

   /**
    * Listens for keyup events.
    */
   keyupListener() {
      window.onkeyup = (e) => {
         if ( e.keyCode === 27 ) {
            this.toggleMenuOff();
         }
      };
   }

   /**
    * Window resize event listener
    */
   resizeListener() {
      window.onresize = () => {
         this.toggleMenuOff();
      };
   }

   /**
    * Turns the custom context menu on.
    */
   toggleMenuOn() {
      if ( this.menuState !== 1 ) {
         this.menuState = 1;
         if(this.contextMenuActive && this.contextMenuActive !== "")
            this.menu.classList.add( this.contextMenuActive );
      }
   }

   /**
    * Turns the custom context menu off.
    */
   toggleMenuOff() {
      if ( this.menuState !== 0 ) {
         this.menuState = 0;
         if(this.contextMenuActive && this.contextMenuActive !== "")
            this.menu.classList.remove( this.contextMenuActive );
         this._selectedRelatedReport = null;
      }
   }

   /**
    * Positions the menu properly.
    *
    * @param {Object} e The event
    */
   positionMenu(e) {
      this.clickCoords = this.getPosition(e);
      this.clickCoordsX = this.clickCoords.x;
      this.clickCoordsY = this.clickCoords.y;

      this.menuWidth = this.menu.offsetWidth + 4;
      this.menuHeight = this.menu.offsetHeight + 4;

      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;

      if ( (this.windowWidth - this.clickCoordsX) < this.menuWidth ) {
         this.menu.style.left = `${this.windowWidth - this.menuWidth  }px`;
      } else {
         this.menu.style.left = `${this.clickCoordsX  }px`;
      }

      if ( (this.windowHeight - this.clickCoordsY) < this.menuHeight ) {
         this.menu.style.top = `${this.windowHeight - this.menuHeight  }px`;
      } else {
         this.menu.style.top = `${this.clickCoordsY  }px`;
      }
   }

   /**
    * Dummy action function that logs an action when a menu item link is clicked
    *
    * @param {HTMLElement} link The link that was clicked
    */
   menuItemListener( link ) {
      console.log( `Task ID - ${  this.taskItemInContext.getAttribute("data-id")  }, Task action - ${  link.getAttribute("data-action")}`);
      this.toggleMenuOff();
   }

   scrollEvent(param) {
      // onRowSelected, onRowClicked 2번 호출하기 때문에 id 확인 후 return 처리함
      // if(this.g_RelatedRow.id === param.id) return;
      // #21626 [HPACS > 나동규원장님 ] 판독된 Related list 클릭시 해당 판독문으로 이동해주는 기능이 있지만, 스크롤을 내렸다가 다시 클릭 시에는 안움직이는 이슈
      // onRowSelected, onRowClicked 2번 호출
      // console.log("scrollEvent: ", this.g_RelatedRow, param);
      this.g_RelatedRow = param;

      const rowId = param.id;
      this.clearRelatedClickViewing();
      this.relatedClickViewing(rowId);
   }

   clearRelatedClickViewing() {
      const {oldFilmContainer} = this.$;
      for(const el of oldFilmContainer.children) {
         if(el.querySelector("paper-icon-button") && el.querySelector("paper-icon-button").icon !== "vaadin:eye") el.querySelector("paper-icon-button").icon = "";
      }
   }

   clearRelatedCurrentViewing() {
      const {oldFilmContainer} = this.$;
      for(const el of oldFilmContainer.children) {
         if(el.querySelector("paper-icon-button") && el.querySelector("paper-icon-button").icon === "vaadin:eye") el.querySelector("paper-icon-button").icon = "";
      }
   }

   initRelatedListStyle() {
      const {oldFilmContainer} = this.$;
      for(const el of oldFilmContainer.children) {
         if(el.querySelector("paper-icon-button")) el.querySelector("paper-icon-button").icon = "";
      }
   }

   relatedClickViewing(id) {
      this.selectedOldFilm = -1;
      const {oldFilmContainer} = this.$;
      for(const el of oldFilmContainer.children) {
         if(id === el.id) {
            el.scrollIntoView(true);
            this.selectedOldFilm = el.index;
         }

         if(el.querySelector("paper-icon-button") && el.querySelector("paper-icon-button").icon === "vaadin:eye" && id === el.id) {
            el.querySelector("paper-icon-button").style.color = "#f77575";
         } else if(el.querySelector("paper-icon-button") && el.querySelector("paper-icon-button").icon === "vaadin:eye" && id !== el.id) {
            el.querySelector("paper-icon-button").style.color = "white";
         } else if(id === el.id) {
            el.querySelector("paper-icon-button").icon = "vaadin:circle";
            el.querySelector("paper-icon-button").style.color = "#f77575";
            el.querySelector("paper-icon-button").style.width = "10px";
            el.querySelector("paper-icon-button").style.height = "10px";
         }
      }
   }

   relatedCurrentViewing(relIds) {
      const {oldFilmContainer} = this.$;
      for(const el of oldFilmContainer.children) {
         if(this.g_RelatedRow.id === el.id) el.scrollIntoView(true);

         relIds.forEach((id) => {
            if(id === el.id) {
               if(!this.g_RelatedRow.id) el.scrollIntoView(true);
               el.querySelector("paper-icon-button").icon = "vaadin:eye";
               el.querySelector("paper-icon-button").style.color = "white";
               el.querySelector("paper-icon-button").style.width = "18px";
               el.querySelector("paper-icon-button").style.height = "18px";
            }
         });
      }
   }

   getUserStyle() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }


   updateWorkListTabIndex(id, tabIndex) {
      const param = { request : tabIndex };
      fetch(`/api/user/option/tabIndex/${id}`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(param.request)
      }).then((response) => {
         if (!response.ok) {
            console.debug(`${response.status} : Response Update TabIndex Failed`);
         }
      });
   }

   onSelectRow(row = {}) {
      this.initRelatedListStyle();
      this.initState();
      if(row.id) this.getRelatedReports(row.id);
   }

   getRelatedReports(id) {
      this.getPreviousReports(id).then((result) => {
         this.setOldFilmList(result, id);
         // store.dispatch({ type: QueryKey.GET_RELATED_WORKLIST, payload: { id, result } });
      }).catch((e) => {
         console.error(e);
      });
   }

   getPreviousReports(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/reports/${id}/previous`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   initState() {
      this.g_SelectedCaseDate = {};

      this.sameBodypart = [];
      this.sameModality = [];
      this.sameModalityAndBodypart = [];
      this.sameStudyDesc = [];
      this.g_reportList = [];

      // #16299 ui 바뀔때 삭제
      this.g_RelatedRow = {};

      this.oldfilm = [];

      this.selectedOldFilm = -1;
   }

   changeCustomContextMenuState(customContextMenuState) {
      if (this.customUniqueKey !== customContextMenuState) {
         if (!this.$.contextMenu.classList.contains("hideContextMenu"))
            this.$.contextMenu.classList.add("hideContextMenu"); // HIDE
      }
   }

   onSelectRelatedRow(r = {}) {
      if(r?.detail) this.scrollEvent(r.detail);
   }
}

window.customElements.define(HHReportOldfilm.is, HHReportOldfilm);
